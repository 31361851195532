import types from './types'

/**
 *
 */
export const fetchProduct = (tagId: string) => ({
  type: types.FETCH_PRODUCT,
  payload: null,
  meta: {
    path: `/products/${tagId}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const deleteProduct = (tagId: string) => ({
  type: types.DELETE_PRODUCT,
  payload: null,
  meta: {
    path: `/products/${tagId}`,
    method: 'DELETE',
    async: true,
  },
})

/**
 *
 */
export const postProduct = (product: any) => ({
  type: types.POST_PRODUCT,
  payload: null,
  meta: {
    path: `/products`,
    method: 'POST',
    async: true,
    body: product,
  },
})

/**
 *
 */
export const postProductFile = (file: FormData) => ({
  type: types.POST_PRODUCT_FILE,
  payload: null,
  meta: {
    path: `/products/upload`,
    method: 'POST',
    async: true,
    body: file,
  },
})
