import React, { PureComponent } from 'react'
import { HTMLTable } from '@blueprintjs/core'

import UserRow from './user-row'

import { IUser as UserType } from '../types'

interface Props {
  users: UserType[]
  onEditUser: (user: UserType) => void
  onDeleteUser: (username: string) => void
  onViewUser: (user: UserType) => void
  onChangeUserPassword: (username: string) => void
}

class UserList extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { users } = this.props
    return (
      <HTMLTable striped>
        <thead>
          <tr>
            <th />
            <th>User</th>
            <th>Name</th>
            <th>Email</th>
            <th>Last Active</th>
            <th>Created</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {users.map(
            (user: UserType, idx: number): JSX.Element => (
              <UserRow
                key={idx}
                {...user}
                onEditUser={(): void => this.props.onEditUser({ ...user })}
                onDeleteUser={(): void =>
                  this.props.onDeleteUser(user.username)
                }
                onViewUser={(): void => this.props.onViewUser({ ...user })}
                onChangeUserPassword={(): void =>
                  this.props.onChangeUserPassword(user.username)
                }
              />
            )
          )}
        </tbody>
      </HTMLTable>
    )
  }
}

export default UserList
