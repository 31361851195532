import * as React from 'react'
import { useState } from 'react'

import { get } from 'lodash'

import Chance from 'chance'

import {
  Button,
  Card,
  FormGroup,
  FileInput,
  HTMLTable,
  Dialog,
  DialogBody,
  TextArea,
  Intent,
} from '@blueprintjs/core'

import { useAppDispatch } from '../../state/hooks'
import { postProductFile } from '../../state/ducks/products/actions'

const genProductLine = (x: number): string => {
  const chance = new Chance()
  const actionTypes = ['validate', 'redirect', 'resource']
  const type = chance.pickone(actionTypes)
  return [
    `q-id.me/${chance.string({
      length: 8,
      alpha: false,
      numeric: true,
    })}.QB01`,
    chance.string({ length: 9, numeric: true }),
    `Product ${x}`,
    chance.string({ length: 9, numeric: true }),
    `${chance.url({ domain: 'q-id.me' })}/${chance.hash({
      length: 15,
    })}`,
    chance.paragraph({ sentences: 1 }),
    chance.country(),
    type,
    type !== 'validate'
      ? `${chance.url({ domain: 'q-id.me' })}/${chance.hash({
          length: 15,
        })}`
      : '',
  ].join(',')
}

export const ProductUpload = ({ ...props }) => {
  const dispatch = useAppDispatch()

  const [file, setFile] = useState<File>()
  const [uploaded, setUploaded] = useState<boolean | undefined>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [showFormat, setShowFormat] = useState(false)

  const Th = ({ children, ...props }: any) => (
    <th style={{ verticalAlign: 'middle' }}>{children}</th>
  )

  return (
    <Card>
      <h4>
        Upload Product CSV
        <Button
          minimal
          intent={Intent.PRIMARY}
          icon={'help'}
          onClick={() => setShowFormat(!showFormat)}
        />
      </h4>

      <Dialog
        style={{ width: '675px' }}
        isOpen={showFormat}
        onClose={() => setShowFormat(false)}
        title="CSV Upload File Format"
        icon="info-sign"
      >
        <DialogBody>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h4>File Format</h4>
            <div style={{ marginBottom: '24px' }}>
              Comma separated values with fields in the following order, no
              header is used:
            </div>
            <div>
              <HTMLTable bordered compact>
                <thead>
                  <tr>
                    <Th style={{ verticalAlign: 'middle' }}>Payload</Th>
                    <Th>Product ID</Th>
                    <Th>Product Name</Th>
                    <Th>Product Serial</Th>
                    <Th>Product URL</Th>
                    <Th>Product Description</Th>
                    <Th>Product Destination</Th>
                    <Th>Forward Action Type</Th>
                    <Th>Forward Action URL</Th>
                  </tr>
                </thead>
              </HTMLTable>
              <div style={{ marginTop: '24px' }}>
                <p>Product destination and description may be left empty.</p>
                <p>
                  If present product destination should be a valid ISO-36116-1
                  alpha-2 Code.
                </p>
                <p>
                  If Forward Action Type is "validate" then Forward Action URL
                  should be left empty.
                </p>
              </div>
            </div>
            <div style={{ alignSelf: 'flex-end', marginTop: '24px' }}>
              <Button
                intent={Intent.PRIMARY}
                icon="download"
                onClick={() => {
                  const content = Array.from({ length: 100 }).map(
                    (_v, idx) => genProductLine(idx) + '\r\n'
                  )
                  const element = document.createElement('a')
                  element.href =
                    'data:text/csv;charset=utf-8,' + encodeURI(content.join(''))
                  element.target = '_blank'
                  element.download = `product-info-sample.csv`
                  element.click()
                }}
              >
                {' '}
                Download Sample{' '}
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      <form>
        <FormGroup
          label="File Select"
          labelFor="text-input"
          labelInfo="(required)"
        >
          <FileInput
            style={{ width: '100%' }}
            text={get(file, ['name'], 'Choose file...')}
            onInputChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setUploaded(undefined)
              if (ev.currentTarget.files) {
                setFile(ev.currentTarget.files[0])
              }
            }}
          />
          <Button
            style={{ marginTop: '12px' }}
            disabled={!file}
            loading={uploading}
            fill
            intent="primary"
            onClick={() => {
              if (file) {
                setUploading(true)

                const formData = new FormData()

                formData.append('products', file, file.name)

                let val: boolean
                dispatch(postProductFile(formData) as any)
                  .then(() => {
                    val = true
                  })
                  .catch((err: any) => {
                    val = false
                  })
                  .finally(() => {
                    setTimeout(() => {
                      setUploaded(val)
                      setUploading(false)
                    }, 500)
                  })
              }
            }}
          >
            Upload
          </Button>
        </FormGroup>
      </form>
      {!uploading && uploaded === true && (
        <div style={{ color: 'green', marginTop: '12px', textAlign: 'center' }}>
          CSV Uploaded
        </div>
      )}
      {!uploading && uploaded === false && (
        <div style={{ color: 'red', marginTop: '12px', textAlign: 'center' }}>
          CSV Upload failed
        </div>
      )}
    </Card>
  )
}

/*
     tagId: record[0],
      metadata: {
        id: record[1],
        name: record[2],
        serial: record[3],
        url: record[4],
      },
      forwardAction: {
        type: record[5],
        uri: record[6] || undefined, // set empty string to undefined
      },
*/
