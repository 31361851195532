import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_VERIFY_KEY: null,
    FETCH_VERIFY_KEY_FULFILLED: null,
    FETCH_VERIFY_KEY_REJECTED: null,
  },
  'keys'
)
