import types from './types'

/**
 *
 */
export const fetchTagErrors = (id: string, query?: string) => ({
  type: types.FETCH_TAG_ERRORS,
  payload: null,
  meta: {
    path: `/tag-errors/${id}${query || ''}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const fetchTagErrorCount = (id: string) => ({
  type: types.FETCH_TAG_ERROR_COUNT,
  payload: null,
  meta: {
    path: `/tag-errors/${id}/count`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const deleteTagErrors = (batchId: string) => ({
  type: types.DELETE_TAG_ERRORS,
  payload: null,
  meta: {
    path: `/tag-errors/${batchId}`,
    method: 'DELETE',
    async: true,
  },
})
