import * as React from 'react'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { get } from 'lodash'

import * as operations from '../../state/ducks/users/operations'

import { CreateUser, IUser } from '@qido/ui-components'

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface State {
  createUserError: string
}

/**
 *
 */
class CreateUserWrapper extends React.Component<Props, State> {
  /**
   *
   */
  public constructor(props: Props) {
    super(props)
    this.state = {
      createUserError: '',
    }
  }

  /**
   *
   */
  private refreshUsers = (): void => {
    this.props.invalidateUsers()
    this.props.fetchUsers()
  }

  /**
   *
   */
  private onCreateUser = (user: any): void => {
    ;(this.props.createUser(user) as any)
      .then(() => {
        this.refreshUsers()
        useNavigate()('/users')
      })
      .catch(() => {})
  }

  public render(): React.ReactNode {
    return (
      <div>
        <h3>Create New User</h3>
        <CreateUser
          creating={this.props.isUpdating}
          onCreateUser={this.onCreateUser}
          onCreateUserFailed={() => {
            console.log('create user failed')
          }}
          error={get(this.props, ['error']) as any}
          username=""
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchUsers: () => dispatch(operations.fetchUsers()),
    createUser: (user: IUser) => dispatch(operations.createUser(user)),
    invalidateUsers: () => dispatch(operations.invalidateUsers()),
  }
}

const mapStateToProps = (state: any) => {
  const { users } = state
  return {
    users: users.data,
    fetching: users.fetching,
    fetched: users.fetched,
    isUpdating: users.isUpdating,
    error: users.error,
    updated: users.updated,
    loading: users.loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserWrapper)
