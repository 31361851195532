import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, InputGroup, Spinner, Intent, Tag } from '@blueprintjs/core'

import { get } from 'lodash'

import { useAppSelector, useAppDispatch } from '../../state/hooks'

import { ImageList } from './image-list'
import {
  fetchPagedImages,
  deleteImages,
} from '../../state/ducks/images/operations'

interface Props {}

const IMAGES_PAGE = 10

export const ImagePage = ({ ...props }: Props) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

  const dispatch = useAppDispatch()
  const params = useParams() as any

  const images = useAppSelector((state) => state.images)
  const batch = useAppSelector((state) => state.batches.byId[params.batchId])

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState<boolean>(false)

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (isAuthenticated) {
      if (!search) {
        dispatch(
          fetchPagedImages(
            (page - 1) * IMAGES_PAGE,
            IMAGES_PAGE,
            params.batchId
          ) as any
        )
      }
    }
  }, [dispatch, isAuthenticated, page, params.batchId, search])

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onSearch = () => {
    console.log('searching:', query)
    dispatch(fetchPagedImages(0, 1000, params.batchId, query) as any)
    setSearch(true)
  }

  if (!images || images.fetching) {
    return <Spinner />
  }

  const imageCount = get(images, ['data', 'length'], 0)
  const tagCount = get(batch, ['tags'], 0)
  const iterations = get(batch, ['iterations'], 0)

  return (
    <div
      style={{
        margin: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      {imageCount > 0 && (
        <div>
          {search ? (
            <Tag
              large
              interactive
              onRemove={() => {
                setSearch(false)
              }}
            >
              {query}
            </Tag>
          ) : (
            <InputGroup
              leftIcon="search"
              placeholder="Search"
              onChange={(ev) => {
                console.log('onChange:', ev.currentTarget.value)
                setQuery(ev.currentTarget.value)
              }}
              rightElement={
                <Button
                  onClick={onSearch}
                  value={query}
                  minimal
                  intent={Intent.PRIMARY}
                  icon={'arrow-right'}
                />
              }
            />
          )}
        </div>
      )}

      <ImageList
        page={page}
        totalPages={search ? 0 : Math.ceil(tagCount / IMAGES_PAGE) * iterations}
        onPageChange={onPageChange}
        images={images}
      />

      {imageCount > 0 && (
        <div
          style={{
            margin: '16px',
          }}
        >
          <Button
            intent="danger"
            fill
            large
            icon="trash"
            onClick={() => {
              dispatch(deleteImages(params.batchId) as any)
            }}
          >
            Delete All
          </Button>
        </div>
      )}
    </div>
  )
}
