import React, { PureComponent } from 'react'

import { FormGroup, InputGroup } from '@blueprintjs/core'

interface Props {
  username?: string
  onChange: (a: Record<string, any>) => void
  isInvalid: boolean
}

export default class UsernameInput extends PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <FormGroup
        intent={this.props.isInvalid ? 'danger' : 'none'}
        label="Username"
        helperText={
          this.props.isInvalid
            ? 'Usernames should be at least 3 characters.'
            : ''
        }
      >
        <InputGroup
          style={{ width: '100%' }}
          name="username"
          type="text"
          placeholder="Username"
          defaultValue={this.props.username}
          onChange={this.props.onChange}
          leftIcon="user"
        />
      </FormGroup>
    )
  }
}
