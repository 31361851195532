/*
 * Copyright 2013-2014 Facebook, Inc.
 * Modifications copyright (C) 2019 BMCE Networks Ltd
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

/**
 * Constructs an enumeration with keys equal to their value.
 *
 * For example:
 *
 *   let COLORS = keyMirror({blue: null, red: null});
 *   let myColor = COLORS.blue;
 *   let isColorValid = !!COLORS[myColor];
 *
 * The last line could not be performed if the values of the generated enum were
 * not equal to their keys.
 *
 *   Input:  {key1: val1, key2: val2}
 *   Output: {key1: key1, key2: key2}
 *
 * @param {object} obj - object to derive key/values from.
 * @param {string} ns - namespace for key values
 * @return {object}
 */

function keyMirror<T>(obj: T, ns = ''): { [K in keyof T]: K } {
  const ret: any = {}
  let key: string
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.')
  }
  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      ret[key] = ns.length ? `${ns}/${key}` : key
    }
  }
  return ret
}

export default keyMirror
