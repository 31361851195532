import { NonIdealState, HTMLTable } from '@blueprintjs/core'

import { QidImage } from '@qido/ui-components'

import { Pagination } from '@qido/ui-components'

interface Patch {
  timestamp: string
  id: string
  payload: string
  qid: number[][][]
}

interface Props {
  patches: any
  page: number
  totalPages: number
  onPageChange: (page: number) => void
  ks: number
}

export function BatchStoreList({
  patches,
  page,
  totalPages,
  onPageChange,
  ks = 64,
}: Props) {
  if ((patches.data || []).length === 0) {
    return (
      <NonIdealState
        icon={'tick'}
        title="No identities currently in the temporary batch store."
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <HTMLTable interactive striped>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Payload</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {(patches.data || []).map((patch: Patch) => (
            <tr key={patch.id}>
              <td>{patch.timestamp}</td>
              <td>{patch.payload}</td>
              <td>
                {patch.qid.map((qid: number[][], idx: number) => (
                  <QidImage key={idx} width={ks} height={ks} qid={qid} />
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {totalPages > 1 && (
        <div style={{ alignSelf: 'center' }}>
          <Pagination
            current={page}
            totalCount={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
