import * as React from 'react'
import { useState } from 'react'

import {
  Button,
  Card,
  InputGroup,
  FormGroup,
  Elevation,
} from '@blueprintjs/core'

import loginStyles from './login.module.scss'


interface Props {
  onLoginClick: (arg0: any) => void;
  error: string;
}

export const Login = ({ ...props }: Props) => {
  const { error } = props

  const [login, setLogin] = useState({ username: '', password: '' })
  const [showPassword, setShowPassword] = useState(false)

  const handleClick = (event: any) => {
    event.preventDefault()
    const creds = {
      username: login.username ? login.username.trim() : '',
      password: login.password ? login.password.trim() : '',
    }
    props.onLoginClick(creds)
  }

  return (
    <div className={loginStyles.login}>
      <h2 style={{ textAlign: 'center', marginBottom: '16px' }}>
        Sign in to Quantum Base
      </h2>
      <Card elevation={Elevation.TWO}>
        <form onSubmit={handleClick}>
          <FormGroup label="Username or email address" labelFor="username">
            <InputGroup
              type="text"
              id="username"
              autoComplete="username"
              onChange={(ev) =>
                setLogin({ ...login, username: ev.currentTarget.value })
              }
              className="form-control"
              placeholder="username/email"
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="password">
            <InputGroup
              id="password"
              key="control"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              onChange={(ev) =>
                setLogin({ ...login, password: ev.currentTarget.value })
              }
              className="form-control"
              placeholder="password"
            />
          </FormGroup>

          <Button intent="primary" type="submit" fill icon="lock">
            Sign in
          </Button>

          {error && (
            <p
              key="errors"
              className="text-center"
              style={{ color: 'red', paddingTop: '12px' }}
            >
              {error}
            </p>
          )}
        </form>
      </Card>
    </div>
  )
}

export default Login