import * as react from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Alignment, Button, Card, Switch } from '@blueprintjs/core'

const ImageSchema = Yup.object().shape({
  save: Yup.boolean().required('R is required'),
})

export const Images = ({ onSubmit, images }: any) => (
  <Card>
    <h5>Image Store</h5>
    <Formik
      enableReinitialize
      initialValues={{
        save: images.save,
      }}
      validationSchema={ImageSchema}
      onSubmit={onSubmit}
    >
      <Form style={{ width: '100%' }}>
        <Field name={'save'}>
          {({ field, form }: any) => (
            <Switch
              alignIndicator={Alignment.RIGHT}
              label="Save Images"
              checked={field.value}
              onChange={(ev) => {
                form.setFieldValue(
                  field.name,
                  (ev.target as HTMLInputElement).checked
                )
              }}
            />
          )}
        </Field>
        <Button icon="floppy-disk" type="submit">
          Save
        </Button>
      </Form>
    </Formik>
  </Card>
)
