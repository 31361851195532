import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_PATCHES: null,
    FETCH_PATCHES_FULFILLED: null,
    FETCH_PATCHES_COMPLETED: null,
    FETCH_PATCHES_REJECTED: null,

    FETCH_CERTIFICATE: null,
    FETCH_CERTIFICATE_FULFILLED: null,
    FETCH_CERTIFICATE_REJECTED: null,
  },
  'patches'
)
