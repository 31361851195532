import React from 'react'

import format from 'date-fns/format'
import { Identicon } from '../identicon'

import { AccountToolbar } from './account-toolbar'
import { IUser as UserType } from '../types'

type Props = UserType

const UserRow = (props: Props): JSX.Element => {
  return (
    <tr style={{ verticalAlign: 'middle' }}>
      <td key="avatar" style={{ verticalAlign: 'middle' }}>
        <Identicon text={props.username} size={50} />
      </td>
      <td>
        <p>{props.username}</p>
        <p style={{ fontStyle: 'italic', color: 'gray' }}>{props.group}</p>
      </td>
      <td key="fullName" style={{ verticalAlign: 'middle' }}>
        {props.fullName}
      </td>
      <td key="email" style={{ verticalAlign: 'middle' }}>
        {props.email}
      </td>
      <td key="lastActive" style={{ verticalAlign: 'middle' }}>
        {format(props.lastActive as any, 'YYYY-MM-DD')}
      </td>
      <td key="createdAt" style={{ verticalAlign: 'middle' }}>
        {format(props.createdAt as any, 'YYYY-MM-DD')}
      </td>
      <td key="actions" style={{ verticalAlign: 'middle' }}>
        <AccountToolbar
          onChangeUserPassword={props.onChangeUserPassword}
          onViewUser={props.onViewUser}
          onEditUser={props.onEditUser}
          onDeleteUser={props.onDeleteUser}
        />
      </td>
    </tr>
  )
}

export default UserRow
