import { fetchTagErrors, fetchTagErrorCount, deleteTagErrors } from './actions'

const fetchPagedTagErrors =
  (offset: number, limit: number, batchId: string, payload?: string) =>
  (dispatch: (f: any) => Promise<any>) => {
    let qs = `?offset=${offset}&limit=${limit}${
      batchId ? `&batchId=${batchId}` : ''
    }`

    if (payload) {
      const encoded = btoa(payload)
      qs = `${qs}&payload=${encoded}`
    }

    return dispatch(fetchTagErrors(batchId, qs))
  }

export {
  fetchTagErrors,
  fetchPagedTagErrors,
  fetchTagErrorCount,
  deleteTagErrors,
}
