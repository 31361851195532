import isNil from 'lodash/isNil'
import get from 'lodash/get'
import { fetch } from '../utils'

import { logout } from '../ducks/auth/operations'

const baseUrl = `/api/v1`

function handleErrors(err: any, action: any, next: any) {
  const e = get(err, 'response.data', err)

  next({
    type: `${action.type}_REJECTED`,
    payload: e,
    meta: action.meta,
  })

  /*
  if (get(err, 'config.url').indexOf('auth/refresh') >= 0) {
    Toast.error(
      'Session revoked, you may have logged in elsewhere.',
      'Error',
      5000
    );
  } else if (typeof e.message === 'string') {
    Toast.error(e.message, 'Error', 5000);
  } else {
    // TODO: Work out what to do here
  }
  */

  if (get(err, 'response.status') === 401) {
    return next(logout())
  }

  return Promise.reject(err)
}

function handleResponse(res: any, action: any, next: any) {
  return next({
    type: `${action.type}_FULFILLED`,
    payload: res.data,
    meta: action.meta,
  })
}

const apiService = () => (next: any) => (action: any) => {
  const result = next(action)
  if (!action.meta || !action.meta.async) {
    return result
  }

  const { path, method = 'GET', body, type } = action.meta
  let { config } = action.meta

  if (!path) {
    throw new Error(`'path' not specified for async action ${action.type}`)
  }

  const url = `${baseUrl}${path}`

  config = { headers: {}, ...config }

  return fetch(url, method, body, config, type).then(
    (res) => handleResponse(res, action, next),
    (err) => handleErrors(err, action, next)
  )
}

export default apiService
