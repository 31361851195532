import { applyMiddleware, createStore, combineReducers } from 'redux'

import {
  createMigrate,
  createTransform,
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { omit } from 'lodash'

import { subscribeStore } from './utils/fetch'

import migrations from './migrations'

import auth from './ducks/auth/reducers'
import batchStore from './ducks/batch-store/reducers'

import batches from './ducks/batches/reducers'
import config from './ducks/config/reducers'
import images from './ducks/images/reducers'
import patches from './ducks/patches/reducers'
import products from './ducks/products/reducers'
import keys from './ducks/keys/reducers'
import qids from './ducks/qids/reducers'
import tagErrors from './ducks/tag-errors/reducers'

import users from './ducks/users/reducers'

import apiService from './middleware/apiService'

let middleware: any = [apiService, thunk]

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, createLogger()] as any
}

const reducer = combineReducers({
  auth,
  batches,
  batchStore,
  config,
  images,
  patches,
  products,
  keys,
  qids,
  tagErrors,
  users,
})

const blacklistTransform = createTransform(
  (inboundState: any, key: any): any => {
    if (key === 'map') {
      const state = omit(inboundState, [
        'transitionInterpolator',
        'transitionEasing',
      ])
      return state
    } else {
      return inboundState
    }
  },
  (outboundState, key) => {
    return { ...outboundState }
  }
)

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [blacklistTransform],
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer, applyMiddleware(...middleware))

const persist = persistStore(store as any)
subscribeStore(store)

export { persist }

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
