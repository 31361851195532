import { handleActions } from 'redux-actions'

import { omit } from 'lodash'

import types from './types'

export interface State {
  data: any[]
  allIds: string[]
  byId: { [s: string]: any }
  fetching: boolean
  fetched: boolean
  isUpdating: boolean
  updated: boolean
  didInvalidate: boolean
  error: string | any
  loading: boolean
}

const initialState: State = {
  data: [],
  allIds: [],
  byId: {},
  fetching: false,
  fetched: false,
  isUpdating: false,
  updated: false,
  didInvalidate: false,
  error: null,
  loading: true,
}

const actionHandler = {
  [types.FETCH_BATCHES]: (state: State) => ({ ...state, fetching: true }),
  [types.FETCH_BATCHES_REJECTED]: (state: State, action: any) => ({
    ...state,
    fetching: false,
    error: action.payload.error,
  }),
  [types.FETCH_BATCHES_FULFILLED]: (state: State, action: any) => {
    const { batches } = action.payload.data

    const byId = batches.reduce((agg: any, curr: any) => {
      return {
        ...agg,
        [curr.id]: curr,
      }
    }, {})

    const allIds = Object.keys(byId)

    return {
      ...state,
      byId,
      allIds,
      fetching: false,
      fetched: true,
      loading: false,
    }
  },

  [types.DELETE_BATCH_FULFILLED]: (state: State, action: any) => {
    const { id } = action.payload.data

    const byId = omit(state.byId, [id])

    const allIds = Object.keys(byId)
    return {
      ...state,
      byId,
      allIds,
      fetching: false,
      fetched: true,
      loading: false,
    }
  },
}

/* Reducer */

export default handleActions(actionHandler, {
  ...initialState,
})
