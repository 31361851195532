import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Card,
  InputGroup,
  Spinner,
  Intent,
  Tag,
} from '@blueprintjs/core'

import { useAppSelector, useAppDispatch } from '../../state/hooks'

import { QidList } from './rejected-qid-list'
import { fetchRejectedPatches } from '../../state/ducks/patches/operations'

interface Props {}

const QIDS_PAGE = 10

export const RejectedQidPage = ({ ...props }: Props) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

  const dispatch = useAppDispatch()
  const params = useParams() as any

  const patches = useAppSelector((state) => state.patches)
  const batch = useAppSelector((state) => state.batches.byId[params.batchId])

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState<boolean>(false)

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (isAuthenticated) {
      if (!search) {
        dispatch(
          fetchRejectedPatches(
            (page - 1) * QIDS_PAGE,
            QIDS_PAGE,
            params.batchId
          ) as any
        )
      }
    }
  }, [dispatch, isAuthenticated, page, params.batchId, search])

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onSearch = () => {
    console.log('searching:', query)
    dispatch(fetchRejectedPatches(0, 1000, params.batchId, query) as any)
    setSearch(true)
  }

  if (!batch || patches.fetching) {
    return <Spinner />
  }

  return (
    <Card>
      {batch.rejectedIdentities > 0 && (
        <div>
          {search ? (
            <Tag
              large
              interactive
              onRemove={() => {
                setSearch(false)
              }}
            >
              {query}
            </Tag>
          ) : (
            <InputGroup
              leftIcon="search"
              placeholder="Search"
              onChange={(ev) => {
                console.log('onChange:', ev.currentTarget.value)
                setQuery(ev.currentTarget.value)
              }}
              rightElement={
                <Button
                  onClick={onSearch}
                  value={query}
                  minimal
                  intent={Intent.PRIMARY}
                  icon={'arrow-right'}
                />
              }
            />
          )}
        </div>
      )}

      <QidList
        page={page}
        totalPages={
          search ? 0 : Math.ceil(batch.rejectedIdentities / QIDS_PAGE)
        }
        onPageChange={onPageChange}
        patches={patches}
        ks={batch.idGenParams.ks}
      />
    </Card>
  )
}
