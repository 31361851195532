import { handleActions } from 'redux-actions'

import jwtDecode from 'jwt-decode'

import types from './types'

export interface State {
  username: string | null
  userId: string | null
  groups: string[] | null
  access: string | null
  refresh: string | null
  isFetching: boolean
  isAuthenticated: boolean
  error: string | null
}

const initialState: State = {
  username: null,
  userId: null,
  groups: null,
  access: null,
  refresh: null,
  isFetching: false,
  isAuthenticated: false,
  error: null,
}

/**
 * Important: Do not rename `actionHandler` or the assignment pattern of property value.
 * It is used to generate documentation
 */
const actionHandler = {
  [types.FETCH_LOGIN]: (state: State) => ({
    ...state,
    isFetching: true,
    isAuthenticated: false,
    error: null,
  }),
  [types.FETCH_LOGIN_FULFILLED]: (state: State, action: any) => {
    const decoded: any = jwtDecode(action.payload.access.replace('Bearer ', ''))

    return {
      ...state,
      isFetching: false,
      isAuthenticated: true,
      access: action.payload.access,
      refresh: action.payload.refresh,
      username: decoded.username,
      userId: decoded.userId,
      groups: decoded.groups,
      error: null,
    }
  },
  [types.FETCH_LOGIN_REJECTED]: (state: State, action: any) => ({
    ...state,
    isFetching: false,
    isAuthenticated: false,
    access: null,
    refresh: null,
    username: null,
    userId: null,
    groups: null,
    error:
      action.payload.error === 'Unauthorized'
        ? 'The username/password combination was invalid'
        : 'Server Error',
  }),
  [types.FETCH_REFRESH_FULFILLED]: (state: State, action: any) => {
    const decoded: any = jwtDecode(action.payload.access.replace('Bearer ', ''))

    return {
      ...state,
      isFetching: false,
      isAuthenticated: true,
      access: action.payload.access,
      username: decoded.username,
      userId: decoded.userId,
      groups: decoded.groups,
      error: '',
    }
  },
  [types.FETCH_REFRESH_REJECTED]: (state: State, action: any) => ({
    ...state,
    isFetching: false,
    isAuthenticated: false,
    refresh: null,
    access: null,
    error: action.payload.message,
  }),
  [types.LOGOUT_REQUEST]: (state: State) => ({
    ...state,
    isFetching: true,
    isAuthenticated: false,
  }),
  [types.LOGOUT_FULFILLED]: (state: State) => ({
    ...state,
    isFetching: false,
    isAuthenticated: false,
    refresh: null,
    access: null,
    username: null,
    userId: null,
    groups: null,
  }),
}

/* Reducer */
export const mapStateReducerFactory = () =>
  handleActions(actionHandler, {
    ...initialState,
  })

export default mapStateReducerFactory()
