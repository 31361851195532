import React, { PureComponent } from 'react'
import { Card, Checkbox } from '@blueprintjs/core'
import { Formik } from 'formik'

import FormError from './create-user/form-error'
import FullNameInput from './create-user/full-name-input'
import UsernameInput from './create-user/username-input'
import EmailInput from './create-user/email-input'
import ConfirmPasswordInput from './create-user/confirm-password-input'
import CreateAccountButton from './create-user/create-account-button'

interface Props {
  onCreateUser: (u: Record<string, any>) => void
  onCreateUserFailed: (u: Record<string, any>) => void
  onEditUser?: (name: string, u: Record<string, any>) => void
  onEditUserFailed?: (name: string, u: Record<string, any>) => void
  editUser?: boolean
  creating?: boolean
  error?: string
  success?: boolean
  username?: string
  fullName?: string
  email?: string
}

interface State {
  validated: boolean
  successText: string
}

interface FormValues {
  username: string
  fullName: string
  email: string
  isAdmin: boolean
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export class CreateUser extends PureComponent<Props, State> {
  private originalUsername = ''

  public static defaultProps = {
    editUser: false,
    fullName: '',
    username: '',
    email: '',
  }

  public constructor(props: Props) {
    super(props)
    this.state = {
      validated: false,
      successText: this.props.editUser
        ? 'Successfully updated user.'
        : 'Sucessfully created user.',
    }
  }

  private validate = (values: Record<string, string>): any => {
    const errors: any = {}

    if (!this.props.editUser) {
      if (!values.password) {
        errors.password = 'Password is required'
      } else if (values.password.length < 8) {
        errors.password = 'Password should be 8 characters or longer.'
      } else if (values.password.length > 64) {
        errors.password = 'Password should be less than 64 characters.'
      }

      if (values.password && !values.confirmPassword) {
        errors.confirmPassword = 'Password confirmation is required'
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Passwords should match.'
      }
    }

    if (!values.username) {
      errors.username = 'Username is required'
    } else if (values.username.length <= 3) {
      errors.username = 'Username should be 3 characters or longer.'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Email should be valid.'
    }

    return errors
  }

  private _onSubmit = (values: Record<string, string>): void => {
    if (!this.props.editUser) {
      const { username, fullName, email, password, password2, isAdmin } = values
      this.props.onCreateUser({
        username,
        fullName,
        email,
        password,
        password2,
        isAdmin
      })
    } else {
      const { username, email, fullName } = values
      if (this.props.onEditUser) {
        this.props.onEditUser(this.originalUsername, {
          username,
          email,
          fullName,
        })
      }
    }
  }

  public render(): React.ReactNode {

   const initialValues: FormValues = {
      isAdmin: false,
      username: this.props.username || '',
      fullName: this.props.fullName || '',
      email: this.props.email || '',
    }


    return (
      <Card>
        <Formik
          enableReinitialize
          onSubmit={this._onSubmit}
          validate={this.validate}
          initialValues={initialValues as any}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            values,
            errors,
          }: any): React.ReactNode => (
            <form noValidate onSubmit={handleSubmit}>
              <FullNameInput
                fullName={values.fullName}
                onChange={handleChange}
              />
              <UsernameInput
                username={values.username}
                onChange={handleChange}
                isInvalid={!!errors.username}
              />
              <EmailInput
                email={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
              />

              {!this.props.editUser && (
                <ConfirmPasswordInput
                  isInvalid={!!errors.password}
                  password={values.password}
                  passwordsMatch={!errors.confirmPassword}
                  onPasswordChange={handleChange}
                  onConfirmChange={handleChange}
                />
              )}
              {!this.props.editUser && (
                <Checkbox checked={values.isAdmin} name="isAdmin" label="Admin User" onChange={handleChange} />
              )}
              <CreateAccountButton
                creating={this.props.creating}
                editUser={this.props.editUser}
              />
            </form>
          )}
        </Formik>
        {this.props.error && <FormError error={this.props.error} />}
        {this.props.success && (
          <span style={{ color: 'green', textAlign: 'center' }}>
            {this.state.successText}
          </span>
        )}
      </Card>
    )
  }
}

export default CreateUser
