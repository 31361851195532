import React, { Component } from 'react'
import { Button, Card } from '@blueprintjs/core'

import UserList from './user-list'

import { IUser } from '../types'

interface Props {
  users: IUser[]
  onCreateUser: () => void
  onDeleteUser: (username: string) => void
  onEditUser: (user: IUser) => void
  onViewUser: (user: IUser) => void
  onChangeUserPassword: (username: string) => void
}

export class UserPanel extends Component<Props> {
  public static defaultProps = {
    users: [],
    onCreateUser: (): void => {
      return
    },
    onDeleteUser: (): void => {
      return
    },
    onEditUser: (): void => {
      return
    },
    onViewUser: (): void => {
      return
    },
    onChangeUserPassword: (): void => {
      return
    },
  }

  public render(): React.ReactNode {
    const { users } = this.props

    if (!users || users.length <= 0) {
      return (
        <Card>
          <h3>No users were found.</h3>
          <form>
            <Button
              icon="plus"
              intent="success"
              onClick={this.props.onCreateUser}
              style={{ marginLeft: '8px' }}
            >
              Create User
            </Button>
          </form>
        </Card>
      )
    }

    return (
      <Card>
        <form>
          <Button
            icon="plus"
            intent="success"
            onClick={this.props.onCreateUser}
            style={{ marginLeft: '8px', marginBottom: '8px' }}
          >
            Create User
          </Button>
        </form>
        <UserList
          users={users}
          onEditUser={this.props.onEditUser}
          onDeleteUser={this.props.onDeleteUser}
          onViewUser={this.props.onViewUser}
          onChangeUserPassword={this.props.onChangeUserPassword}
        />
      </Card>
    )
  }
}

export default UserPanel
