import types from './types'

/**
 *
 */
export const fetchImages = (query?: string) => ({
  type: types.FETCH_IMAGES,
  payload: null,
  meta: {
    path: `/images${query || ''}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const deleteImages = (batchId: string) => ({
  type: types.DELETE_IMAGES,
  payload: null,
  meta: {
    path: `/images/${batchId}`,
    method: 'DELETE',
    async: true,
  },
})

/**
 *
 */
export const fetchImagesForBatch = (id: string) => fetchImages(`?batchId=${id}`)

/**
 *
 */
export const fetchImageData = (id: string) => ({
  type: types.FETCH_IMAGE_DATA,
  payload: null,
  meta: {
    path: `/images/${id}`,
    method: 'GET',
    async: true,
    config: {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    },
  },
})
