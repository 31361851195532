import * as React from 'react'
import { IUser, User } from '@qido/ui-components'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import DeleteUserModal from './delete-user-modal'

import NotFound from '../components/not-found'

import * as operations from '../../state/ducks/users/operations'

type Props = { error: string } & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface State {
  showDeleteUserModal: boolean
  deleteUserUsername: string
}

/**
 *
 */
class ViewUserContainer extends React.Component<Props, State> {
  /**
   *
   */
  constructor(props: Props) {
    super(props)

    this.state = {
      showDeleteUserModal: false,
      deleteUserUsername: '',
    }
  }

  /**
   *
   */
  refreshUsers = () => {
    this.props.invalidateUsers()
    return this.props.fetchUsers()
  }

  componentDidMount() {
    this.refreshUsers()
  }

  /**
   *
   */
  onDeleteUserButClicked = (username: string) => {
    this.setState({ deleteUserUsername: username, showDeleteUserModal: true })
  }

  /**
   *
   */
  onCloseDeleteDialog = () => {
    this.setState({ deleteUserUsername: '', showDeleteUserModal: false })
  }

  /**
   *
   */
  onEditUserButClicked = (user: IUser) => {
    const navigate = useNavigate()
    navigate('/admin/users/' + user.username + '/edit')
  }

  /**
   *
   */
  onChangeUserPasswordButClicked = (username: string) => {
    const navigate = useNavigate()
    navigate('/admin/users/' + username + '/password')
  }

  /**
   *
   */
  onDeleteUser = (username: string) => {
    this.setState({ deleteUserUsername: '', showDeleteUserModal: false })
    this.props.deleteUser(username).then(() => {
      const navigate = useNavigate()
      navigate('/admin/users')
    })
  }

  public render(): React.ReactNode {
    if (this.props.fetched === true && this.props.fetching === false) {
      const { users } = this.props

      const { username } = useParams()

      if (!username || !(username in users)) {
        return <NotFound />
      }

      return (
        <div>
          <User
            key="user"
            {...users[`${username}`]}
            onDeleteUser={this.onDeleteUserButClicked}
            onEditUser={this.onEditUserButClicked}
            onChangeUserPassword={this.onChangeUserPasswordButClicked}
          />
          <DeleteUserModal
            key="delete"
            username={this.state.deleteUserUsername}
            show={this.state.showDeleteUserModal}
            onClose={this.onCloseDeleteDialog}
            onDelete={this.onDeleteUser}
            probe={false}
          />
        </div>
      )
    } else {
      return <div>Loading</div>
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchUsers: () => dispatch(operations.fetchUsers()),
    invalidateUsers: () => dispatch(operations.invalidateUsers()),
    deleteUser: (username: string) => dispatch(operations.deleteUser(username)),
  }
}

const mapStateToProps = (state: any) => {
  const { users } = state
  return {
    users: users.byId,
    fetching: users.fetching,
    fetched: users.fetched,
    isUpdating: users.isUpdating,
    error: users.error,
    updated: users.updated,
    loading: users.loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserContainer)
