import * as React from 'react'
import { Fragment } from 'react'

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  NavLink,
} from 'react-router-dom'

import { Button, Navbar, Spinner } from '@blueprintjs/core'

import { get } from 'lodash'

import { useAppDispatch, useAppSelector } from '../../state/hooks'

import { BatchList } from './batch-list'

import { BatchInfo } from './batch-info'
import { PoolStats } from './batch-pool-stats'

import {
  deleteBatch,
  fetchBatches,
  completeBatch,
} from '../../state/ducks/batches/operations'
import { fetchBatchStoreTagCount } from '../../state/ducks/batch-store/operations'

import { isAdmin as isAdminSelector } from '../../state/ducks/auth/selectors'

import { QidPage } from '../qids/qid-page'
import { BatchStorePage } from '../batch-store/batch-store-page'

import { RejectedQidPage } from '../rejected-qids/rejected-qid-page'
import { TagErrorPage } from '../tag-errors/tag-error-page'

import { ImagePage } from '../images/image-page'

export const BatchPage = ({ ...props }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const batches = useAppSelector((state) => state.batches)
  const isAdmin = useAppSelector((state) => isAdminSelector(state.auth))

  const onDeleteBatch = () => {
    const batchId = getBatchId()
    if (batchId) {
      dispatch(deleteBatch(batchId) as any)
      navigate(`/`)
    } else {
      console.error('Tried to delete a non-existent batch')
    }
  }

  const onCompleteBatch = () => {
    const batchId = getBatchId()
    if (batchId) {
      const tags = batches.byId[batchId].tags

      dispatch(fetchBatchStoreTagCount(batchId) as any).then((action: any) => {
        const count = action.payload.data.count

        dispatch(completeBatch(batchId, count) as any).then(() => {
          dispatch(fetchBatches() as any)
        })
      })
    } else {
      console.error('Tried to complete a non-existent batch')
    }
  }

  const selectBatch = (batchId: string) => {
    navigate(`/batch/${batchId}/info`)
  }

  const getBatchId = (): string | undefined => {
    const params = location.pathname.split('/')

    if (params.length > 2 && params[1] === 'batch') {
      return params[2]
    }
    return
  }

  const content = batches.fetching ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : (
    <div style={{ margin: '6px' }}>
      <BatchList
        selectedBatch={getBatchId()}
        batches={batches}
        onSelectBatch={selectBatch}
      />
    </div>
  )

  const BatchInfoComponent = () => {
    const { batchId } = useParams()
    return (
      <BatchInfo
        {...props}
        isAdmin={isAdmin}
        batch={batches.byId[batchId!!]}
        onComplete={onCompleteBatch}
        onDelete={onDeleteBatch}
      />
    )
  }

  const PoolStatsComponent = () => {
    const { batchId } = useParams()
    return (
      <PoolStats statistics={get(batches.byId[batchId!!], ['statistics'])} />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
      }}
    >
      {content}
      <Routes>
        <Route
          path="/batch/:batchId/*"
          element={
            <div style={{ margin: '16px' }}>
              <Navbar>
                <Navbar.Group>
                  <NavLink to="info">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="home"
                        text="Batch Info"
                      />
                    )}
                  </NavLink>
                  <NavLink to="identities">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="endorsed"
                        text="Identities"
                      />
                    )}
                  </NavLink>
                  <NavLink to="pool-check">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="chart"
                        text="Pool Check"
                      />
                    )}
                  </NavLink>
                  <NavLink to="store">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="archive"
                        text="Batch Store"
                      />
                    )}
                  </NavLink>
                  <NavLink to="tag-errors">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="warning-sign"
                        text="Tag Errors"
                      />
                    )}
                  </NavLink>
                  <NavLink to="rejected">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="cross"
                        text="Rejected"
                      />
                    )}
                  </NavLink>
                  <NavLink to="images">
                    {({ isActive }) => (
                      <Button
                        active={isActive}
                        minimal
                        icon="media"
                        text="Images"
                      />
                    )}
                  </NavLink>
                </Navbar.Group>
              </Navbar>
              <Routes>
                <Route path="info" element={<BatchInfoComponent />} />
                <Route path="pool-check" element={<PoolStatsComponent />} />
                <Route path="identities" element={<QidPage />} />
                <Route path="store" element={<BatchStorePage />} />
                <Route path="tag-errors" element={<TagErrorPage />} />
                <Route path="rejected" element={<RejectedQidPage />} />
                <Route path="images" element={<ImagePage />} />
                <Route path="" element={<BatchInfoComponent />} />
              </Routes>
            </div>
          }
        />

        <Route
          path="*"
          element={
            <div style={{ margin: '16px' }}>
              <h3>Select a batch</h3>
            </div>
          }
        />
      </Routes>
    </div>
  )
}
