import React, { useState, useEffect } from 'react'

import { Route, Routes } from 'react-router-dom'

import classNames from 'classnames'

import { Login } from '@qido/ui-components'

import Nav from './nav'

import * as auth from '../state/ducks/auth/operations'
import { isAdmin as isAdminSelector } from '../state/ducks/auth/selectors'

import { fetchBatches } from '../state/ducks/batches/operations'

import { useAppSelector, useAppDispatch } from '../state/hooks'

import './app.module.scss'

import Users from './users/users'
import { ProductPage } from './products/products-page'

import { BatchPage } from './batches/batch-page'
import { ClientBatchPage } from './client-batches/client-batch-page'

import { SettingsPage } from './settings/settings-page'

export function App() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const isAdmin = useAppSelector((state) => isAdminSelector(state.auth))

  const authError = useAppSelector((state) => state.auth.error)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchBatches() as any)
    }
    return
  }, [dispatch, isAuthenticated])

  const [observer] = useState(
    () =>
      new PerformanceObserver((list) => {
        const entries = list.getEntries()
        console.log(
          `${entries.length} long ${
            entries.length === 1 ? 'task' : 'tasks'
          } detected`
        )
        const lastDuration = entries[entries.length - 1].duration
        console.log(
          `${
            lastDuration ? `Last duration: ${Math.round(lastDuration)}ms` : ''
          }`
        )
      })
  )

  useEffect(() => {
    observer.observe({ entryTypes: ['longtask'] })
    return () => observer.disconnect()
  }, [observer])

  const [showDrawer, setShowDrawer] = useState(false)

  if (isAuthenticated) {
    return (
      <div
        className={classNames('app')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Nav
          isAuthenticated={isAuthenticated}
          logout={() => dispatch(auth.logout())}
          isAdmin={isAdmin}
        />
        {isAdmin && (
          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/*" element={<BatchPage />} />
          </Routes>
        )}
        {!isAdmin && (
          <Routes>
            <Route path="/products" element={<ProductPage />} />
            <Route path="/*" element={<ClientBatchPage />} />
          </Routes>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <Nav
          isAuthenticated={isAuthenticated}
          logout={() => dispatch(auth.logout())}
          isAdmin={false}
        />
        <Login
          error={authError}
          onLoginClick={(creds) => {
            dispatch(auth.login(creds))
          }}
        />
      </div>
    )
  }
}

export default App
