import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_IMAGE_SETTINGS: null,
    FETCH_IMAGE_SETTINGS_FULFILLED: null,
    FETCH_IMAGE_SETTINGS_REJECTED: null,

    POST_IMAGE_SETTINGS: null,
    POST_IMAGE_SETTINGS_FULFILLED: null,
    POST_IMAGE_SETTINGS_REJECTED: null,

    FETCH_ID_POOL_SETTINGS: null,
    FETCH_ID_POOL_SETTINGS_FULFILLED: null,
    FETCH_ID_POOL_SETTINGS_REJECTED: null,

    POST_ID_POOL_SETTINGS: null,
    POST_ID_POOL_SETTINGS_FULFILLED: null,
    POST_ID_POOL_SETTINGS_REJECTED: null,
  },
  'config'
)
