import * as React from 'react'
import { connect } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom'

import isNil from 'lodash/isNil'

import { CreateUser } from '@qido/ui-components'

import NotFound from '../components/not-found'

import * as operations from '../../state/ducks/users/operations'
import * as selectors from '../../state/ducks/users/selectors'

type Props = { error: string } & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

interface State {
  editUserError: string | any
}

/**
 *
 */
class EditUserContainer extends React.Component<Props, State> {
  /**
   *
   */
  public constructor(props: any) {
    super(props)
    this.state = {
      editUserError: null,
    }
  }

  /**
   *
   */
  private refreshUsers = (): void => {
    this.props.invalidateUsers()
    this.props.fetchUsers()
  }

  /**
   *
   */
  public componentDidMount(): void {
    this.refreshUsers()
  }

  /**
   *
   */
  private onEditUser = (
    originalUsername: string,
    user: Record<string, any>
  ): void => {
    const { username } = useParams()

    const navigate = useNavigate()

    this.setState({ editUserError: null })

    const editUser: (a: string, b: any) => Promise<void> = this.props
      .editUser as any

    editUser(username!!, user).then((): void => {
      this.refreshUsers()
      if (username !== user.username) {
        navigate('/users/' + user.username + '/edit')
      }
    })
  }

  /**
   *
   */
  private onEditUserFailed = (
    originalUsername: string,
    user: Record<string, any>
  ): void => {
    const editUserFailedError = null
    /*
    if (!UsernameRegexCheck(user.username)) {
      editUserFailedError = 'Username Validation Failed\n'
    }
    if (!EmailRegexCheck(user.email)) {
      editUserFailedError = 'Email Validation Failed\n'
    }
    */
    this.setState({ editUserError: editUserFailedError })
  }

  /**
   *
   */
  private getErrorState(): string | Record<string, string> | null {
    if (!isNil(this.props.error)) {
      return this.props.error
    } else if (!isNil(this.state.editUserError)) {
      return this.state.editUserError
    } else {
      return null
    }
  }

  public render(): React.ReactNode {
    if (this.props.fetched === true && this.props.fetching === false) {
      const { username } = useParams()
      const { users } = this.props

      if (!username || !(username in users)) {
        return <NotFound />
      }

      const error = this.getErrorState()

      return (
        <div>
          <h3 key="title">Edit User</h3>
          <CreateUser
            key="edit"
            creating={this.props.isUpdating}
            onEditUser={this.onEditUser}
            onEditUserFailed={this.onEditUserFailed}
            error={error}
            success={this.props.updated && error === null}
            editUser
            {...users[username]}
          />
        </div>
      )
    } else {
      return <div>Loading</div>
    }
  }
}

interface MapState {
  users: Record<string, any>
  fetching: boolean
  fetched: boolean
  isUpdating: boolean
  error: string | string[] | Error | null
  updated: boolean
}

const mapDispatchToProps = {
  fetchUsers: operations.fetchUsers,
  editUser: operations.editUser,
  invalidateUsers: operations.invalidateUsers,
}

const mapStateToProps = (state: any): MapState => {
  const { users } = state
  return {
    users: users.byId,
    fetching: users.fetching,
    fetched: users.fetched,
    isUpdating: users.isUpdating,
    error: selectors.getErrors(users),
    updated: users.updated,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserContainer as any)
