import * as React from 'react'
import { useEffect } from 'react'

import { Button } from '@blueprintjs/core'

import { IdentityPool } from './identity-pool'
import { Images } from './images'
import { QidParams } from './qid-params'

import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { fetchParams, postParams } from '../../state/ducks/qids/operations'
import {
  fetchImageSettings,
  fetchIdentityPoolCheck,
  postImageSettings,
  postIdentityPoolCheck,
} from '../../state/ducks/config/actions'
import { fetchVerifyKey } from '../../state/ducks/keys/operations'

import { download } from '../../util/download'

export const SettingsPage = ({ ...props }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchParams() as any)
    dispatch(fetchImageSettings() as any)
    dispatch(fetchIdentityPoolCheck() as any)
  }, [dispatch])

  const saveParams = ({ R, N, ks }: any) => {
    dispatch(postParams({ R, N, ks }))
  }

  const saveImageSettings = ({ save }: any) => {
    dispatch(postImageSettings({ save }))
  }

  const saveIdPoolCheckSettings = ({
    tolerance,
    interAvg,
    interStd,
    numberOfBits,
    degreesOfFreedom,
    limit,
  }: any) => {
    dispatch(
      postIdentityPoolCheck({
        thresholds: {
          uniformity: {
            tolerance,
          },
          interAvg,
          interStd,
          numberOfBits,
          degreesOfFreedom,
        },
        settings: {
          limit,
        },
      })
    )
  }

  const params = useAppSelector((state) => state.qids.params)
  const { images, identityPoolCheck } = useAppSelector((state) => state.config)

  return (
    <div
      style={{
        margin: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'space-between',
      }}
    >
      <QidParams
        params={params}
        onSubmit={(values: any) => {
          saveParams(values)
        }}
      />
      <Images
        images={images}
        onSubmit={(values: any) => {
          saveImageSettings(values)
        }}
      />
      <IdentityPool
        identityPoolCheck={identityPoolCheck}
        onSubmit={(values: any) => {
          saveIdPoolCheckSettings(values)
        }}
      />

      <div style={{ margin: '48px' }}>
        <Button
          onClick={() => {
            dispatch(fetchVerifyKey() as any).then((res: any) => {
              const blob = new Blob([res.payload], {
                type: 'application/octet-stream',
              })
              const objectUrl = URL.createObjectURL(blob)
              download(objectUrl, `qb.pub`)
            })
          }}
        >
          Download Verify Key
        </Button>
      </div>
    </div>
  )
}
