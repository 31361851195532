import * as react from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, Callout, Card } from '@blueprintjs/core'

import { ParamField } from './param-field'

const ParamsSchema = Yup.object().shape({
  R: Yup.number().required('R is required'),
  N: Yup.number().required('N is required'),
  ks: Yup.number().required('ks is required'),
})

export const QidParams = ({ onSubmit, params }: any) => (
  <Card>
    <h5>QID Algorithm Parameters</h5>
    <Formik
      enableReinitialize
      initialValues={{
        R: params.R,
        N: params.N,
        ks: params.ks,
      }}
      validationSchema={ParamsSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form style={{ width: '100%' }}>
          <ParamField errors={errors} touched={touched} name="R" label="R" />
          <ParamField errors={errors} touched={touched} name="N" label="N" />
          <ParamField errors={errors} touched={touched} name="ks" label="ks" />
          <Button icon="floppy-disk" type="submit">
            Save
          </Button>
        </Form>
      )}
    </Formik>
    {params.error && (
      <Callout style={{ margin: '8px' }} intent="danger">
        {params.error}
      </Callout>
    )}
  </Card>
)
