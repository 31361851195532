import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_BATCH_STORE: null,
    FETCH_BATCH_STORE_FULFILLED: null,
    FETCH_BATCH_STORE_COMPLETED: null,
    FETCH_BATCH_STORE_REJECTED: null,

    DELETE_BATCH_STORE: null,
    DELETE_BATCH_STORE_FULFILLED: null,
    DELETE_BATCH_STORE_REJECTED: null,

    FETCH_BATCH_STORE_SIZE: null,
    FETCH_BATCH_STORE_SIZE_FULFILLED: null,
    FETCH_BATCH_STORE_SIZE_REJECTED: null,
  },
  'batchStore'
)
