import { fetchPatches, fetchCertificate, fetchPatchesForBatch } from './actions'

/*
const fetchPagedData = (fetchFn: any, completed: string, batchId?: string) => async (
  dispatch: (f: any) => Promise<any>
) => {
  try {
    let data: any[] = []

    let cursor = 0

    while (cursor !== null) {
      const qs = `?cursor=${cursor}${batchId ? `&batchId=${batchId}` : ''}`
      const f = fetchFn(qs)
      const res = await dispatch(f)
      cursor = res.payload.cursor
      data = [].concat.apply([], [data, res.payload.data])
    }

    return dispatch({
      type: completed,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}
*/

const fetchPagedPatches =
  (offset: number, limit: number, batchId?: string, payload?: string) =>
  (dispatch: (f: any) => Promise<any>) => {
    let qs = `?offset=${offset}&limit=${limit}${
      batchId ? `&batchId=${batchId}` : ''
    }`

    if (payload) {
      const encoded = btoa(payload)
      qs = `${qs}&payload=${encoded}`
    }

    return dispatch(fetchPatches(qs))
  }

const fetchRejectedPatches =
  (offset: number, limit: number, batchId?: string, payload?: string) =>
  (dispatch: (f: any) => Promise<any>) => {
    let qs = `?rejected=true&offset=${offset}&limit=${limit}${
      batchId ? `&batchId=${batchId}` : ''
    }`

    if (payload) {
      const encoded = btoa(payload)
      qs = `${qs}&payload=${encoded}`
    }

    return dispatch(fetchPatches(qs))
  }

export {
  fetchPagedPatches,
  fetchPatches,
  fetchCertificate,
  fetchPatchesForBatch,
  fetchRejectedPatches,
}
