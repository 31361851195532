import * as React from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'

interface Props {
  username: string
  onDelete: (arg0: string) => void
  probe: boolean
  show: boolean
  onClose: () => void
}

/**
 *
 */
class DeleteUserProbeModal extends React.Component<Props> {
  /**
   *
   */
  onDeleteClicked = () => {
    this.props.onDelete(this.props.username)
  }

  /**
   *
   */
  onCancelClicked = () => {
    this.props.onClose()
  }

  public render(): React.ReactNode {
    const { props } = this

    return (
      <Dialog
        isOpen={this.props.show}
        onClose={this.props.onClose}
        title={`Delete User ${props.username}?`}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Are you sure you want to delete {`'${props.username}'`} from the QB
            MVS?
          </p>
          <p>This action cannot be undone.</p>
        </div>

        <div
          className={Classes.DIALOG_FOOTER}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            style={{ marginLeft: '10px' }}
            intent="danger"
            className="col-sm-2 pull-right"
            onClick={this.onDeleteClicked}
          >
            Delete
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            intent="warning"
            className="col-sm-2 pull-right"
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default DeleteUserProbeModal
