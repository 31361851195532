import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_PARAMS: null,
    FETCH_PARAMS_FULFILLED: null,
    FETCH_PARAMS_REJECTED: null,

    POST_PARAMS: null,
    POST_PARAMS_FULFILLED: null,
    POST_PARAMS_REJECTED: null,
  },
  'qids'
)
