import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_IMAGES: null,
    FETCH_IMAGES_FULFILLED: null,
    FETCH_IMAGES_COMPLETED: null,
    FETCH_IMAGES_REJECTED: null,

    DELETE_IMAGES: null,
    DELETE_IMAGES_FULFILLED: null,
    DELETE_IMAGES_REJECTED: null,

    FETCH_IMAGE_DATA: null,
    FETCH_IMAGE_DATA_FULFILLED: null,
    FETCH_IMAGE_DATA_REJECTED: null,
  },
  'images'
)
