export { default as fetch } from './fetch'

export interface FetchTypes {
  FETCH: string
  FETCH_FULFILLED: string
  FETCH_REJECTED: string
}

export const createFetchTypes = (name: string, namespace = '') => ({
  [`FETCH`]: `${namespace}FETCH_${name}`,
  [`FETCH_FULFILLED`]: `${namespace}FETCH_${name}_FULFILLED`,
  [`FETCH_REJECTED`]: `${namespace}FETCH_${name}_REJECTED`,
})

export const createReducer =
  (initialState: any) =>
  (reducerMap: any) =>
  (state: any = initialState, action: any) => {
    const reducer = reducerMap[action.type]
    return reducer ? reducer(state, action) : state
  }
