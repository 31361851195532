import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_PRODUCT: null,
    FETCH_PRODUCT_FULFILLED: null,
    FETCH_PRODUCT_REJECTED: null,

    DELETE_PRODUCT: null,
    DELETE_PRODUCT_FULFILLED: null,
    DELETE_PRODUCT_REJECTED: null,

    POST_PRODUCT: null,
    POST_PRODUCT_FULFILLED: null,
    POST_PRODUCT_REJECTED: null,

    POST_PRODUCT_FILE: null,
    POST_PRODUCT_FILE_PROGRESS: null,
    POST_PRODUCT_FILE_FULFILLED: null,
    POST_PRODUCT_FILE_REJECTED: null,
  },
  'products'
)
