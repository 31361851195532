import {
  fetchBatchStore,
  fetchBatchStoreSize,
  deleteBatchStore,
  fetchBatchStoreTagCount as _fetchBatchStoreTagCount,
} from './actions'

export const fetchPagedBatchStore =
  (offset: number, limit: number, batchId: string, payload?: string) =>
  (dispatch: (f: any) => Promise<any>) => {
    let qs = `?offset=${offset}&limit=${limit}`

    if (payload) {
      const encoded = btoa(payload)
      qs = `${qs}&payload=${encoded}`
    }

    return dispatch(fetchBatchStore(batchId, qs))
  }

const fetchBatchStoreTagCount =
  (batchId: string) => (dispatch: (f: any) => Promise<any>) => {
    return dispatch(_fetchBatchStoreTagCount(batchId))
  }

export {
  fetchBatchStore,
  fetchBatchStoreSize,
  deleteBatchStore,
  fetchBatchStoreTagCount,
}
