import React, { PureComponent } from 'react'

import { FormGroup, InputGroup } from '@blueprintjs/core'

interface Props {
  fullName: string
  onChange: (a: Record<string, any>) => void
}

export default class FullNameInput extends PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <FormGroup label="Full Name">
        <InputGroup
          style={{ width: '100%' }}
          type="text"
          name="fullName"
          placeholder="Full Name"
          defaultValue={this.props.fullName}
          onChange={this.props.onChange}
          leftIcon="user"
        ></InputGroup>
      </FormGroup>
    )
  }
}
