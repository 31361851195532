import React from 'react'

interface Props {
  text: string
  size: number
}

const COLORS = [
  '#7c00c4',
  '#9900ff',
  '#0088ff',
  '#0073ff',
  '#00d9ff',
  '#ff6600',
  '#ff9898',
  '#ff0066',
  '#45cea2',
  '#339999',
  '#021631',
  '#fb8166',
  '#f1dc39',
  '#f18039',
  '#39f1dc',
  '#39aaf1',
  '#0a5787',
  '#456f9a',
  '#70459a',
  '#459a70',
  '#9b5f4f',
  '#9a7045',
  '#339999',
  '#343d46',
  '#1a1d23',
  '#3f0000',
]

class Identicon extends React.PureComponent<Props> {
  public static defaultProps = {
    size: 64,
    text: '',
  }

  private textToColor = (text: string): string => {
    if (text.length > 0) {
      return COLORS[text.charCodeAt(0) % COLORS.length]
    } else {
      return COLORS[0]
    }
  }

  public render(): React.ReactNode {
    let { text, size } = this.props
    text = text.substring(0, 2).toUpperCase()
    return (
      <div
        style={{
          height: `${size}px`,
          width: `${size}px`,
          position: 'relative',
        }}
      >
        <svg
          viewBox="0 0 512 512"
          width="512"
          height="512"
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <circle cx="255" cy="255" r="230" fill={this.textToColor(text)} />
          <text
            x="255"
            y="246"
            alignmentBaseline="central"
            textAnchor="middle"
            fill="#fff"
            fontFamily="Lato"
            fontSize="255"
            fontWeight="300"
          >
            {text}
          </text>
        </svg>
      </div>
    )
  }
}

export { Identicon }
