import types from './types'

/**
 *
 */
export const fetchBatchStore = (id: string, query?: string) => ({
  type: types.FETCH_BATCH_STORE,
  payload: null,
  meta: {
    path: `/batch-store/${id}${query || ''}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const fetchBatchStoreSize = (id: string) => ({
  type: types.FETCH_BATCH_STORE_SIZE,
  payload: null,
  meta: {
    path: `/batch-store/${id}/size`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const fetchBatchStoreTagCount = (id: string) => ({
  type: types.FETCH_BATCH_STORE_SIZE,
  payload: null,
  meta: {
    path: `/batch-store/${id}/tag-count`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const deleteBatchStore = (batchId: string) => ({
  type: types.DELETE_BATCH_STORE,
  payload: null,
  meta: {
    path: `/batch-store/${batchId}`,
    method: 'DELETE',
    async: true,
  },
})
