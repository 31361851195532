import {
  Button,
  NonIdealState,
  HTMLTable,
  Toaster,
  Position,
  Intent,
} from '@blueprintjs/core'

import { get } from 'lodash'

import { QidImage } from '@qido/ui-components'

import { useAppDispatch } from '../../state/hooks'
import { fetchCertificate } from '../../state/ducks/patches/operations'
import { Pagination } from '@qido/ui-components'

function download(dataUri: string, fileName: string) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = dataUri
  link.setAttribute('download', fileName)
  const body = document.body
  if (body) {
    body.appendChild(link)
    link.click()
    body.removeChild(link)
  }
}

interface Patch {
  timestamp: string
  id: string
  payload: string
  qid: number[][]
}

interface Props {
  patches: any
  page: number
  totalPages: number
  ks: number
  onPageChange: (page: number) => void
}

export function QidList({
  patches,
  page,
  totalPages,
  onPageChange,
  ks = 64,
}: Props) {
  const dispatch = useAppDispatch()

  if ((patches.data || []).length === 0) {
    return (
      <NonIdealState
        icon={'search'}
        title="No identities registered in batch."
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <HTMLTable striped interactive>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Payload</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {(patches.data || []).map((patch: Patch) => (
            <tr key={patch.id}>
              <td>{patch.timestamp}</td>
              <td>{patch.payload}</td>
              <td>
                <QidImage width={ks} height={ks} qid={patch.qid} />
              </td>
              <td>
                <Button
                  intent="success"
                  onClick={() => {
                    const encoded = encodeURIComponent(patch.payload)
                    dispatch(fetchCertificate(encoded) as any)
                      .then((res: any) => {
                        const filename = patch.payload.split('/')
                        const blob = new Blob([res.payload], {
                          type: 'application/octet-stream',
                        })
                        const objectUrl = URL.createObjectURL(blob)
                        download(
                          objectUrl,
                          `${filename[filename.length - 1]}.crt`
                        )
                      })
                      .catch((err: any) => {
                        let message
                        if (get(err, ['response', 'status']) === 404) {
                          message = `Certificate not found: ${patch.payload}`
                        } else if (get(err, ['response', 'status']) === 500) {
                          message = `Server error. Please contact QB admin if the issue persists.`
                        } else {
                          message = `Failed to download certificate: ${patch.payload}. Check network connection.`
                        }

                        Toaster.create({
                          position: Position.TOP,
                        }).show({
                          message,
                          intent: Intent.DANGER,
                        })
                      })
                  }}
                >
                  Download Certificate
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {totalPages > 1 && (
        <div style={{ alignSelf: 'center' }}>
          <Pagination
            current={page}
            totalCount={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
