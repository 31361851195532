import types from './types'

import jwtDecode from 'jwt-decode'

/**
 *
 */
export const requestRefresh = (deviceId: string) => ({
  type: types.FETCH_REFRESH,
  meta: {
    type: 'refresh',
    path: `/auth/refresh`,
    method: 'POST',
    async: true,
    body: {
      deviceId,
    },
  },
})

/**
 *
 */
export const receiveRefresh = (user: any) => ({
  type: types.FETCH_REFRESH_FULFILLED,
  payload: {
    decoded: jwtDecode(user.access.replace('Bearer ', '')),
    access: user.access,
  },
})

/**
 *
 */
export const refreshError = (message: string) => ({
  type: types.FETCH_REFRESH_REJECTED,
  payload: {
    message,
  },
})

/**
 *
 */
export const requestLogin = (
  username: string,
  password: string,
  deviceId: string
) => ({
  type: types.FETCH_LOGIN,
  meta: {
    type: 'authenticate',
    path: `/auth/authenticate`,
    method: 'POST',
    async: true,
    body: {
      username,
      password,
      deviceId,
    },
  },
})

/**
 *
 */
export const receiveLogin = (response: any) => ({
  type: types.FETCH_LOGIN_FULFILLED,
  payload: {
    decoded: jwtDecode(response.access.replace('Bearer ', '')),
    refresh: response.refresh,
    access: response.access,
  },
})

/**
 *
 */
export const loginError = (message: string) => ({
  type: types.FETCH_LOGIN_REJECTED,
  payload: {
    message,
  },
})

/**
 *
 */
export const requestLogout = () => ({
  type: types.LOGOUT_REQUEST,
})

/**
 *
 */
export const receiveLogout = () => ({
  type: types.LOGOUT_FULFILLED,
})
