import {
  Button,
  NonIdealState,
  HTMLTable,
  Dialog,
  DialogBody,
} from '@blueprintjs/core'

import { useState } from 'react'

import { useAppDispatch } from '../../state/hooks'
import { Pagination } from '@qido/ui-components'

interface ImageData {
  timestamp: string
  id: string
  payload: string
  image: {
    data: number[]
    type: string
  }
  qid?: number[][]
}

interface Props {
  images: any
  page: number
  totalPages: number
  onPageChange: (page: number) => void
}

export function ImageList({ images, page, totalPages, onPageChange }: Props) {
  const dispatch = useAppDispatch()

  const [imageOverlay, setImageOverlay] = useState<string | undefined>()
  const [imagePayload, setImagePayload] = useState<string>('')

  if ((images.data || []).length === 0) {
    return (
      <NonIdealState icon={'search'} title="No image data saved for batch." />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <HTMLTable striped interactive>
        <thead>
          <tr>
            <th>Payload</th>
            <th>Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(images.data || []).map((image: ImageData) => {
            const arr = new Uint8Array(image.image.data)
            const blob = new Blob([arr], { type: 'image/png' })
            const url = URL.createObjectURL(blob)
            return (
              <tr
                onClick={() => {
                  setImageOverlay(url)
                  setImagePayload(image.payload)
                }}
                key={image.id}
              >
                <td style={{ verticalAlign: 'middle' }}>{image.payload}</td>
                <td>
                  <img
                    style={{
                      verticalAlign: 'middle',
                      width: '48px',
                      height: '48px',
                    }}
                    src={url}
                  />
                </td>
                <td></td>
              </tr>
            )
          })}
        </tbody>
      </HTMLTable>
      {totalPages > 1 && (
        <div style={{ alignSelf: 'center' }}>
          <Pagination
            current={page}
            totalCount={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
      <Dialog
        title={imagePayload}
        isOpen={!!imageOverlay}
        onClose={() => setImageOverlay(undefined)}
      >
        <DialogBody>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{}} src={imageOverlay} />
          </div>
        </DialogBody>
      </Dialog>
    </div>
  )
}
