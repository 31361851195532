import React, { PureComponent } from 'react'

import { FormGroup, InputGroup } from '@blueprintjs/core'

interface Props {
  email?: string
  onChange: (a: Record<string, any>) => void
  isInvalid: boolean
}

export default class EmailInput extends PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <FormGroup
        label="Email"
        intent={this.props.isInvalid ? 'danger' : 'none'}
        helperText={this.props.isInvalid ? "Please provide a valid email address." : ''}
      >
        <InputGroup
          style={{ width: '100%' }}
          leftIcon="envelope"
          name="email"
          type="text"
          defaultValue={this.props.email}
          placeholder="Email"
          onChange={this.props.onChange}
        ></InputGroup>
      </FormGroup>
    )
  }
}
