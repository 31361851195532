import {
  Card,
  Colors,
  Icon,
  Spinner,
  NonIdealState,
  HTMLTable,
  Classes,
} from '@blueprintjs/core'

interface Props {
  batches: any
  onSelectBatch: (batchId: string) => void
  selectedBatch?: string | null
}

export function BatchList({ batches, onSelectBatch, selectedBatch }: Props) {
  const { byId, allIds } = batches

  if (!allIds || allIds.length === 0) {
    return (
      <Card>
        <h4>Q-ID Batches</h4>
        <NonIdealState icon={'search'} title="No batches found." />
      </Card>
    )
  }

  const getActiveStyle = (id: string) => {
    return id === selectedBatch
      ? {
          backgroundColor: Colors.DARK_GRAY5,
          color: Colors.WHITE,
        }
      : {}
  }

  return (
    <Card>
      <h4>Batches</h4>
      <HTMLTable striped interactive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Timestamp</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(batches.allIds || []).map((id: string) => (
            <tr
              key={id}
              style={getActiveStyle(id)}
              className={id === selectedBatch ? Classes.DARK : ''}
              onClick={() => {
                onSelectBatch(id)
              }}
            >
              <td style={getActiveStyle(id)}>{byId[id].customerBatchId}</td>
              <td style={getActiveStyle(id)}>{byId[id].timestamp}</td>
              <td style={getActiveStyle(id)}>
                {byId[id].completed ? (
                  <Icon intent="success" icon="tick-circle" />
                ) : (
                  <Spinner intent="primary" size={15} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  )
}
