import React, { Component } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import ViewUser from './view-user'

import UserList from './user-list'

import CreateUser from './create-user'

import EditUser from './edit-user'

import ChangePassword from './change-password'

interface Props {}

/**
 *
 */
class UserRoutes extends Component<Props> {
  public render(): React.ReactNode {
    return (
      <div style={{ margin: '1rem' }}>
        <Routes>
          <Route path={`:username/password`} element={<ChangePassword />} />
          <Route path={`:username/edit`} element={<EditUser />} />
          <Route path={`create`} element={<CreateUser />} />
          <Route path={`:username`} element={<ViewUser />} />
          <Route path={``} element={<UserList />} />
        </Routes>
      </div>
    )
  }
}

export default UserRoutes
