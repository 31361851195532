import { Action, handleActions } from 'redux-actions'

import types from './types'

export interface State {
  data: any[]
  allIds: string[]
  byId: { [s: string]: any }
  fetching: boolean
  fetched: boolean
  isUpdating: boolean
  updated: boolean
  didInvalidate: boolean
  error: string | any
  loading: boolean
}

const initialState: State = {
  data: [],
  allIds: [],
  byId: {},
  fetching: false,
  fetched: false,
  isUpdating: false,
  updated: false,
  didInvalidate: false,
  error: null,
  loading: true,
}

interface User {
  username: string
  Groups: { name: string }[]
}

const actionHandler = {
  [types.INVALIDATE_USERS]: (state: State) => ({
    ...state,
    didInvalidate: true,
  }),
  [types.FETCH_USERS]: (state: State) => ({ ...state, fetching: true }),
  [types.FETCH_USERS_REJECTED]: (state: State, action: any) => ({
    ...state,
    fetching: false,
    error: action.payload,
  }),
  [types.FETCH_USERS_FULFILLED]: (state: State, action: any) => {
    let byId = {}
    let allIds: string[] = []

    const users = action.payload.map((u: User) => ({
      ...u,
      group: u.Groups && u.Groups.length > 0 ? u.Groups[0].name : 'user',
    }))

    if (users) {
      byId = users.reduce((result: Record<string, User>, user: User) => {
        result[user.username] = user
        return result
      }, {})
      allIds = users.map((user: User) => user.username)
    }

    return {
      ...state,
      byId,
      allIds,
      fetching: false,
      fetched: true,
      data: users,
      loading: false,
    }
  },
  [types.CANCEL_USER_UPDATE]: (state: State) => ({
    ...state,
    isUpdating: false,
    updated: false,
  }),
  [types.CREATE_USER]: (state: State) => ({
    ...state,
    isUpdating: true,
    updated: false,
    error: null,
  }),
  [types.CREATE_USER_REJECTED]: (
    state: State,
    action: Action<{ message: any }>
  ) => ({
    ...state,
    isUpdating: false,
    updated: false,
    error: action.payload.message,
  }),
  [types.CREATE_USER_FULFILLED]: (state: State) => ({
    ...state,
    isUpdating: false,
    updated: true,
    error: null,
  }),
  [types.CHANGE_PASSWORD]: (state: State) => ({
    ...state,
    isUpdating: true,
    updated: false,
    error: null,
  }),
  [types.CHANGE_PASSWORD_REJECTED]: (state: State, action: Action<any>) => ({
    ...state,
    isUpdating: false,
    updated: false,
    error: action.payload,
  }),
  [types.CHANGE_PASSWORD_FULFILLED]: (state: State) => ({
    ...state,
    isUpdating: false,
    updated: true,
    error: null,
  }),
  [types.EDIT_USER]: (state: State) => ({
    ...state,
    isUpdating: true,
    updated: false,
    error: null,
  }),
  [types.EDIT_USER_REJECTED]: (state: State, action: Action<any>) => ({
    ...state,
    isUpdating: false,
    updates: false,
    error: action.payload,
  }),
  [types.EDIT_USER_FULFILLED]: (state: State) => ({
    ...state,
    isUpdating: false,
    updated: true,
    error: null,
  }),
  [types.DELETE_USER]: (state: State) => ({ ...state, isUpdating: true }),
  [types.DELETE_USER_REJECTED]: (state: State, action: Action<any>) => ({
    ...state,
    isUpdating: false,
    error: action.payload,
  }),
  [types.DELETE_USER_FULFILLED]: (state: State) => ({
    ...state,
    isUpdating: false,
    updated: true,
    error: null,
  }),
}

/* Reducer */

export default handleActions(actionHandler, {
  ...initialState,
})
