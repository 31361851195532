import { Button, MenuItem } from '@blueprintjs/core'
import { ItemPredicate, ItemRenderer, Select2 } from '@blueprintjs/select'
import React from 'react'

import { get } from 'lodash'

import { iso3166, iso3166Ids } from '@qido/shared/countries'

const filterCountry: ItemPredicate<string> = (
  query,
  code,
  _index,
  exactMatch
) => {
  const country = iso3166[code]

  const normalizedName = country.name.toLowerCase()
  const normalizedCode = country['alpha-2'].toLowerCase()

  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedName === normalizedQuery
  } else {
    return `${normalizedName} ${normalizedCode}`.indexOf(normalizedQuery) >= 0
  }
}

const renderCountry: ItemRenderer<string> = (
  code,
  { handleClick, handleFocus, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }

  const country = iso3166[code]

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={country['alpha-2']}
      label={country['alpha-2'].toString()}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={`${country.name}`}
    />
  )
}

export const CountrySelect = ({
  onItemSelected,
}: {
  onItemSelected: (code?: string) => void
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState<
    string | undefined
  >()

  const onItemSelect = (code?: string) => {
    onItemSelected(code)
    setSelectedCountry(code)
  }

  const buttonText = selectedCountry
    ? iso3166[selectedCountry]['name']
    : 'Select a country'

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Select2<string>
        fill
        items={iso3166Ids}
        itemPredicate={filterCountry}
        itemRenderer={renderCountry}
        menuProps={{ 'aria-label': 'countries' }}
        noResults={
          <MenuItem
            disabled={true}
            text="No results."
            roleStructure="listoption"
          />
        }
        onItemSelect={onItemSelect}
      >
        <Button
          fill
          icon="geosearch"
          text={buttonText}
          rightIcon="caret-down"
        />
      </Select2>
      <Button
        disabled={!selectedCountry}
        icon="cross"
        onClick={() => onItemSelect(undefined)}
      />
    </div>
  )
}
