import types from './types'

/**
 *
 */
export const fetchBatches = () => ({
  type: types.FETCH_BATCHES,
  payload: null,
  meta: {
    path: `/batches`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const fetchBatch = (id: string) => ({
  type: types.FETCH_BATCH,
  payload: id,
  meta: {
    path: `/batches/${id}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const completeBatch = (id: string, tags: number) => ({
  type: types.COMPLETE_BATCH,
  payload: id,
  meta: {
    path: `/batches/${id}/complete`,
    method: 'POST',
    async: true,
    body: {
      tags,
    },
  },
})

/**
 *
 */
export const deleteBatch = (id: string) => ({
  type: types.DELETE_BATCH,
  payload: id,
  meta: {
    path: `/batches/${id}`,
    method: 'DELETE',
    async: true,
  },
})
