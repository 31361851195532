import { Button, HTMLTable, Card } from '@blueprintjs/core'

interface Props {
  batch: any
}

export function ClientBatchInfo({ batch }: Props) {
  if (!batch) {
    return <div>No batch data</div>
  }

  return (
    <Card>
      <h4>{batch.customerBatchId}</h4>
      <HTMLTable striped interactive>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Timestamp</td>
            <td>{batch.timestamp}</td>
          </tr>
          <tr>
            <td>Manufacturer</td>
            <td>{batch.manufacturerName}</td>
          </tr>
          <tr>
            <td>Customer</td>
            <td>{batch.customerName}</td>
          </tr>
          <tr>
            <td>Factory</td>
            <td>{batch.productionFactory}</td>
          </tr>
          <tr>
            <td>Line</td>
            <td>{batch.productionLine}</td>
          </tr>
          <tr>
            <td>Technique</td>
            <td>{batch.productionTechnique}</td>
          </tr>
          <tr>
            <td>Quantum Material</td>
            <td>{batch.quantumMaterial}</td>
          </tr>
          <tr>
            <td>Substrate</td>
            <td>{batch.substrate}</td>
          </tr>
          <tr>
            <td>Validity Start</td>
            <td>{batch.tagValidityStart}</td>
          </tr>
          <tr>
            <td>Validity End</td>
            <td>{batch.tagValidityEnd}</td>
          </tr>
          <tr>
            <td>Warranty Start</td>
            <td>{batch.tagWarrantyStart}</td>
          </tr>
          <tr>
            <td>Warranty End</td>
            <td>{batch.tagWarrantyEnd}</td>
          </tr>
          <tr>
            <td>Expected Tags</td>
            <td>{batch.expectedIdentities * batch.iterations}</td>
          </tr>
          <tr>
            <td>Tags Processed</td>
            <td>{batch.tags}</td>
          </tr>
          <tr>
            <td>Expected Identities</td>
            <td>{batch.expectedIdentities}</td>
          </tr>
          <tr>
            <td>Total Identities</td>
            <td>{batch.totalIdentities}</td>
          </tr>
          <tr>
            <td>Rejected Identities</td>
            <td>{batch.rejectedIdentities}</td>
          </tr>
          <tr>
            <td>Iterations</td>
            <td>{batch.iterations}</td>
          </tr>
          <tr>
            <td>Completed</td>
            <td>{batch.completed ? 'true' : 'false'}</td>
          </tr>
        </tbody>
      </HTMLTable>
    </Card>
  )
}
