import * as React from 'react'

import { get } from 'lodash'

import { Card, NonIdealState, Pre } from '@blueprintjs/core'

interface Props {
  statistics: any
}

const Payload = ({ payload, stats }: any) => {
  return (
    <div style={{ margin: '2px', padding: '2px', overflow: 'hidden' }}>
      <h5>{payload}</h5>
      <Pre>
        <div>Inter Avg (Uniqueness): {stats.inter_avg}</div>
        <div>Inter Std: {stats.inter_stdev}</div>
        <div>Uniformity: {stats.uniformity}</div>
        <div>Number of Bits: {stats.number_of_bits}</div>
        <div>
          Degrees of Freedom: {stats.degrees_of_freedom[0]},{' '}
          {stats.degrees_of_freedom[1]}
        </div>
      </Pre>
    </div>
  )
}

const getData = (stats: any, label: string, key: string) => {
  return (
    <div>
      <h5>{label}</h5>
      <div style={{ marginLeft: '8px', fontSize: 'small' }}>
        <p>AVG {get(stats, [key, 'avg'], 0)}</p>
        <p>STD: {get(stats, [key, 'stdev'], 0)}</p>
        <p>Min {get(stats, [key, 'min'], 0)}</p>
        <p>Max {get(stats, [key, 'max'], 0)}</p>
      </div>
    </div>
  )
}

const Overview = ({ stats }: any) => {
  return (
    <div style={{ margin: '2px', padding: '2px', overflow: 'hidden' }}>
      <h5>Aggregate Stats</h5>
      <Pre>
        {getData(stats, 'Inter Average (Uniqueness)', 'inter_avg')}
        {getData(stats, 'Inter Std', 'inter_stdev')}
        {getData(stats, 'Uniformity', 'uniformity')}
        {getData(stats, 'Number of Bits', 'number_of_bits')}
        {getData(stats, 'Degrees of Freedom', 'degrees_of_freedom')}
      </Pre>
    </div>
  )
}

export function PoolStats({ statistics }: Props) {
  const data = get(statistics, ['data'])

  if (!data) {
    return (
      <Card style={{ minWidth: '500px' }}>
        <h4>Identity Pool Check</h4>
        <NonIdealState
          icon={'search'}
          title="Identity Pool Check not completed for batch."
        />
      </Card>
    )
  }

  const { identities, aggregate } = data

  return (
    <Card style={{ minWidth: '500px' }}>
      <h4>Identity Pool Check</h4>
      {aggregate && <Overview stats={aggregate} />}
      {Object.keys(identities).map((key) => (
        <Payload key={key} payload={key} stats={identities[key]} />
      ))}
    </Card>
  )
}
