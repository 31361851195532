import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_BATCHES: null,
    FETCH_BATCHES_FULFILLED: null,
    FETCH_BATCHES_REJECTED: null,

    FETCH_BATCH: null,
    FETCH_BATCH_FULFILLED: null,
    FETCH_BATCH_REJECTED: null,

    DELETE_BATCH: null,
    DELETE_BATCH_FULFILLED: null,
    DELETE_BATCH_REJECTED: null,

    COMPLETE_BATCH: null,
    COMPLETE_BATCH_FULFILLED: null,
    COMPLETE_BATCH_REJECTED: null,
  },
  'batches'
)
