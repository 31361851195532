import * as React from 'react'
import { useState } from 'react'

import { get } from 'lodash'

import {
  Button,
  Card,
  InputGroup,
  NonIdealState,
  HTMLTable,
  Spinner,
  Intent,
} from '@blueprintjs/core'

import { iso3166 } from '@qido/shared/countries'

import {
  fetchProduct,
  deleteProduct,
} from '../../state/ducks/products/operations'

import { useAppDispatch, useAppSelector } from '../../state/hooks'

const errorProps = (code: number): Record<string, string> => {
  switch (code) {
    case 404:
      return {
        icon: 'search',
        title: 'No Search results',
        description: `The product information for the tag couldn't be found.`,
      }
    case -1:
      return {
        icon: 'satellite',
        title: 'Communication Error',
        description: `Failed to request the produict information from the server.`,
      }
    default:
      return {
        icon: 'error',
        title: 'Server Error',
        description: `The server failed to lookup the requested tag.`,
      }
  }
}

export const ProductFind = ({ ...props }) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [tagId, setTagId] = useState('')
  const [product, setProduct] = useState<Record<string, string>>()
  const [error, setError] = useState(0)

  const onTagIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTagId(ev.currentTarget.value)
  }

  const findProductInfo = (tagId: string) => {
    setLoading(true)
    dispatch(fetchProduct(tagId) as any)
      .then((product: any) => {
        setError(0)
        setProduct(product.payload)
      })
      .catch((err: any) => {
        if (err && err.response) {
          if (err.response.status === 404) {
            setError(err.response.status)
          } else {
            setError(err.response.status)
          }
        } else {
          setError(-1)
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 250)
      })
  }

  const _deleteProduct = (tagId: string) => {
    dispatch(deleteProduct(tagId) as any)
      .then((product: any) => {
        setError(0)
        setProduct(undefined)
      })
      .catch((err: any) => {
        if (err && err.response) {
          if (err.response.status === 404) {
            setError(err.response.status)
          } else {
            setError(err.response.status)
          }
        } else {
          setError(-2)
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 250)
      })
  }

  const getCountryName = (
    product: Record<string, string>
  ): string | undefined => {
    const code = get(product, ['metadata', 'destination'])
    return code in iso3166 ? iso3166[code].name : code
  }

  return (
    <Card>
      <h3>Find Product</h3>
      <InputGroup
        onChange={onTagIdChange}
        value={tagId}
        id="text-input"
        placeholder="q-id.me/FB384FE.QB01"
        rightElement={
          <Button
            fill
            intent="primary"
            icon="search"
            minimal={true}
            onClick={() => findProductInfo(encodeURIComponent(tagId))}
          />
        }
      />
      <div style={{ marginTop: '16px' }}>
        {loading && <Spinner />}
        {!loading && !!product && (
          <div>
            <HTMLTable striped bordered compact style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Tag ID</td>
                  <td>{get(product, ['tagId'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>ID</td>
                  <td>{get(product, ['metadata', 'id'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Name</td>
                  <td>{get(product, ['metadata', 'name'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Serial</td>
                  <td>{get(product, ['metadata', 'serial'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>URL</td>
                  <td>{get(product, ['metadata', 'url'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Description</td>
                  <td>
                    {get(product, ['metadata', 'description'], 'Unknown')}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Destination</td>
                  <td>{getCountryName(product)}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Type</td>
                  <td>{get(product, ['forwardAction', 'type'], 'Unknown')}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>URI</td>
                  <td>{get(product, ['forwardAction', 'uri'], 'Unknown')}</td>
                </tr>
              </tbody>
            </HTMLTable>
            <div style={{ marginTop: '12px' }}>
              <Button
                loading={loading}
                icon="trash"
                fill
                intent={Intent.DANGER}
                onClick={() => _deleteProduct(encodeURIComponent(tagId))}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        {!loading && !!error && <NonIdealState {...errorProps(error)} />}
      </div>
    </Card>
  )
}
