import * as React from 'react'
import { IUser, UserPanel } from '@qido/ui-components'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import DeleteUserModal from './delete-user-modal'

import * as operations from '../../state/ducks/users/operations'

type Props = { error: string } & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface State {
  showDeleteUserModal: boolean
  deleteUserUsername: string
}

/**
 *
 */
class UserListContainer extends React.Component<Props, State> {
  static defaultProps: any = {
    users: [],
  }

  state = {
    showDeleteUserModal: false,
    deleteUserUsername: '',
  }

  componentDidMount() {
    this.props.fetchUsers()
  }

  /**
   *
   */
  refreshUsers = () => {
    this.props.invalidateUsers()
    this.props.fetchUsers()
  }

  /**
   *
   */
  onDeleteUser = (username: string) => {
    this.setState({ deleteUserUsername: '', showDeleteUserModal: false })
    this.props.deleteUser(username).then(() => {
      this.refreshUsers()
    })
  }

  /**
   *
   */
  onDeleteUserButClicked = (username: string) => {
    this.setState({ deleteUserUsername: username, showDeleteUserModal: true })
  }

  /**
   *
   */
  onCloseDeleteDialog = () => {
    this.setState({ deleteUserUsername: '', showDeleteUserModal: false })
  }

  /**
   *
   */
  onEditUserButClicked = (user: IUser) => {
    useNavigate()('/users/' + user.username + '/edit')
  }

  /**
   *
   */
  onViewUser = (user: IUser) => {
    useNavigate()('/users/' + user.username)
  }

  /**
   *
   */
  onChangeUserPasswordButClicked = (username: string) => {
    useNavigate()('/users/' + username + '/password')
  }

  /**
   *
   */
  onCreateUserButClicked = () => {
    useNavigate()('/users/create')
  }

  public render(): React.ReactNode {
    const { deleteUserUsername, showDeleteUserModal } = this.state

    console.log(deleteUserUsername)

    if (this.props.fetched === true && this.props.fetching === false) {
      return (
        <div>
          <h3>Users</h3>
          <UserPanel
            users={this.props.users}
            onDeleteUser={this.onDeleteUserButClicked}
            onViewUser={this.onViewUser}
            onEditUser={this.onEditUserButClicked}
            onCreateUser={this.onCreateUserButClicked}
            onChangeUserPassword={this.onChangeUserPasswordButClicked}
          />

          {showDeleteUserModal && (
            <DeleteUserModal
              username={deleteUserUsername}
              show={this.state.showDeleteUserModal}
              onClose={this.onCloseDeleteDialog}
              onDelete={this.onDeleteUser}
              probe={false}
            />
          )}
        </div>
      )
    } else {
      return <div>Loading</div>
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchUsers: () => dispatch(operations.fetchUsers()),
    deleteUser: (username: string) => dispatch(operations.deleteUser(username)),
    invalidateUsers: () => dispatch(operations.invalidateUsers()),
  }
}

const mapStateToProps = (state: any) => {
  const { users } = state
  return {
    users: users.data,
    fetching: users.fetching,
    fetched: users.fetched,
    isUpdating: users.isUpdating,
    error: users.error,
    updated: users.updated,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer)
