import { Icon, Intent } from '@blueprintjs/core'
import React, { useRef, useEffect, useState } from 'react'


interface Props {
  qid: number[][]
  height: number
  width: number
}

export function QidImage({ qid, height, width }: Props) {
  const canvasRef = useRef(null)

  const [error, setError] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas !== null) {
      const context = (canvas as any).getContext('2d', {
        willReadFrequently: true,
      })
      const imageData = context.getImageData(0, 0, height, width)

      try {
        for (let i = 0; i < height; i++) {
          for (let j = 0; j < width; j++) {
            const dy = i * width * 4
            let pos = dy + j * 4

            const val = qid[i][j] === 1 ? 255 : 0

            imageData.data[pos++] = val
            imageData.data[pos++] = val
            imageData.data[pos++] = val
            imageData.data[pos++] = 255
            context.putImageData(imageData, 0, 0)
          }
        }
      } catch (err) {
        setError(true)
      }
    }
  }, [canvasRef, height, width, qid])

  if (error) {
    return <Icon size={64} icon='error' intent={Intent.DANGER} />
  }

  return (
    <canvas
      style={{ width: '64px', height: '64px' }}
      ref={canvasRef}
      width={width}
      height={height}
      id="canvas"
    >
      The browser doesn't support the canvas element
    </canvas>
  )
}

export default QidImage
