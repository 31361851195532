import { handleActions } from 'redux-actions'

export interface State {}

const initialState: State = {}

const actionHandler = {}

/* Reducer */

export default handleActions(actionHandler, {
  ...initialState,
})
