import { NonIdealState, HTMLTable } from '@blueprintjs/core'

import { QidImage } from '@qido/ui-components'

import { Pagination } from '@qido/ui-components'

interface TagError {
  timestamp: string
  id: string
  payload: string
  qid: number[][]
  uniformity: number
  error: string
  code: number
}

interface Props {
  patches: {
    data: TagError[]
  }
  page: number
  totalPages: number
  onPageChange: (page: number) => void
  ks: number
}

const validateQid = (qid: number[][]) => {
  return Array.isArray(qid) && qid.length >= 64 && qid[0].length > 64
}

export function TagErrorList({
  patches,
  page,
  totalPages,
  onPageChange,
  ks = 64,
}: Props) {
  if ((patches.data || []).length === 0) {
    return (
      <NonIdealState icon={'tick'} title="No identities rejected in batch." />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <HTMLTable striped interactive>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Payload</td>
            <td>Uniformity</td>
            <td>Error</td>
            <td>QID</td>
          </tr>
        </thead>
        <tbody>
          {(patches.data || []).map((patch: TagError) => (
            <tr key={patch.id}>
              <td>{patch.timestamp}</td>
              <td>{patch.payload}</td>
              <td>{Number(patch.uniformity).toFixed(3)}</td>
              <td>{patch.error}</td>
              <td>
                {validateQid(patch.qid) && (
                  <QidImage width={ks} height={ks} qid={patch.qid} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {totalPages > 1 && (
        <div style={{ alignSelf: 'center' }}>
          <Pagination
            current={page}
            totalCount={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
