import * as React from 'react'
import { ChangePassword } from '@qido/ui-components'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import NotFound from '../components/not-found'

import * as operations from '../../state/ducks/users/operations'

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchUsers: () => dispatch(operations.fetchUsers()),
    changeUserPassword: (username: string, newPassword: string) =>
      dispatch(operations.changeUserPassword(username, newPassword)),
    invalidateUsers: () => dispatch(operations.invalidateUsers()),
  }
}

const mapStateToProps = (state: any) => {
  const { users } = state
  return {
    users: users.byId,
    fetching: users.fetching,
    fetched: users.fetched,
    isUpdating: users.isUpdating,
    error: users.error,
    updated: users.updated,
    loading: users.loading,
  }
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

/**
 *
 */
class ChangePasswordContainer extends React.Component<Props> {
  /**
   *
   */
  private refreshUsers = (): void => {
    this.props.invalidateUsers()
    this.props.fetchUsers()
  }

  public componentDidMount(): void {
    this.refreshUsers()
  }

  /**
   *
   */
  private onChangeUserPassword = (
    username: string,
    newPassword: string
  ): void => {
    const navigate = useNavigate()

    this.props.changeUserPassword(username, newPassword).then((): void => {
      console.log(this.props)
      this.refreshUsers()
      navigate(-1)
    })
  }

  public render(): React.ReactNode {
    if (this.props.fetched === true && this.props.fetching === false) {
      const { users } = this.props

      const { username } = useParams()

      if (!username || !(username in users)) {
        return <NotFound />
      }

      return (
        <div>
          <h3>Change Password For {username}</h3>
          <ChangePassword
            onUpdate={this.onChangeUserPassword}
            onPasswordChangeError={console.error}
            updating={this.props.isUpdating}
            username={username}
            error={this.props.error && this.props.error.message}
            requireAuth={false}
          />
        </div>
      )
    } else {
      return <div>Loading</div>
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer)
