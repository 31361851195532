import React, { PureComponent } from 'react'

import { Button, FormGroup } from '@blueprintjs/core'

interface Props {
  creating?: boolean
  editUser?: boolean
}

export default class CreateAccountButton extends PureComponent<Props> {
  public render(): React.ReactNode {
    let { creating, editUser } = this.props

    return (
      <FormGroup>
        <Button fill type="submit" intent="success" loading={creating}>
          {!creating && !editUser && <span>Create</span>}
          {!creating && editUser && <span>Save</span>}
        </Button>
      </FormGroup>
    )
  }
}
