import types from './types'

import { ImageSettings, IdentityPoolSettings } from './reducers'

import { QidParams } from '@qido/shared'

/**
 *
 */
export const fetchQidParams = () => ({
  type: types.FETCH_IMAGE_SETTINGS,
  payload: null,
  meta: {
    path: `/config/images`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const postQidParams = ({ R, N, ks }: QidParams) => ({
  type: types.POST_IMAGE_SETTINGS,
  payload: {
    R,
    N,
    ks,
  },
  meta: {
    path: `/config/images`,
    method: 'POST',
    async: true,
    body: {
      R,
      N,
      ks,
    },
  },
})

/**
 *
 */
export const fetchImageSettings = () => ({
  type: types.FETCH_IMAGE_SETTINGS,
  payload: null,
  meta: {
    path: `/config/images`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const postImageSettings = ({ save }: ImageSettings) => ({
  type: types.POST_IMAGE_SETTINGS,
  payload: {
    save,
  },
  meta: {
    path: `/config/images`,
    method: 'POST',
    async: true,
    body: {
      save,
    },
  },
})

/**
 *
 */
export const fetchIdentityPoolCheck = () => ({
  type: types.FETCH_ID_POOL_SETTINGS,
  payload: null,
  meta: {
    path: `/config/identity-pool-check`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const postIdentityPoolCheck = (settings: IdentityPoolSettings) => ({
  type: types.POST_ID_POOL_SETTINGS,
  payload: settings,
  meta: {
    path: `/config/identity-pool-check`,
    method: 'POST',
    async: true,
    body: settings,
  },
})
