import * as React from 'react'
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core'

import { Formik } from 'formik'

import FormError from './create-user/form-error'

interface Props {
  requireAuth: boolean
  onUpdate: (p1: string, p2: string) => void
  updating: boolean
  error: string
  username: string
  onPasswordChangeError: (err: string) => void
}

export class ChangePassword extends React.PureComponent<Props, any> {
  public constructor(props: Props) {
    super(props)
  }

  private _onSubmit = (values: Record<string, string>): void => {
    if (this.props.requireAuth) {
      return this.props.onUpdate(values.currentPassword, values.password)
    } else {
      return this.props.onUpdate(this.props.username, values.password)
    }
  }

  private validate = (
    values: Record<string, string>
  ): Record<string, string> => {
    const errors: Record<string, string> = {}

    if (!values.password) {
      errors.password = 'Password is required'
    } else if (values.password.length < 4) {
      errors.password = 'Password should be longer than 4 characters.'
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Password confirmation is required'
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords should match.'
    }

    if (!values.password) {
      errors.password = 'Password is required'
    } else if (values.password.length < 4) {
      errors.password = 'Password should be longer than 4 characters.'
    }

    return errors
  }

  public render(): React.ReactNode {
    const { error, updating } = this.props
    return (
      <Card>
        <Formik
          enableReinitialize
          onSubmit={this._onSubmit}
          validate={this.validate}
          initialValues={{
            currentPassword: '',
            password: '',
            confirmPassword: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            values,
            errors,
          }: any): React.ReactNode => (
            <form onSubmit={handleSubmit}>
              <FormGroup
                intent={
                  !!errors.password && !!touched.password ? 'danger' : 'none'
                }
                label="New Password"
              >
                <InputGroup
                  leftIcon="lock"
                  style={{ width: '100%' }}
                  name="password"
                  type="password"
                  value={values.password}
                  placeholder="New Password"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup
                intent={
                  !!errors.confirmPassword && !!touched.confirmPassword
                    ? 'danger'
                    : 'none'
                }
                label="Confirm New Password"
              >
                <InputGroup
                  style={{ width: '100%' }}
                  leftIcon="lock"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  placeholder="Confirm New Password"
                  onChange={handleChange}
                />
              </FormGroup>
              {this.props.requireAuth && (
                <FormGroup
                  intent={
                    !!errors.currentPassword && !!touched.currentPassword
                      ? 'danger'
                      : 'none'
                  }
                  label="Current Password"
                >
                  <InputGroup
                    leftIcon="lock"
                    name="currentPassword"
                    type="password"
                    value={values.currentPassword}
                    placeholder="Current Password"
                    onChange={handleChange}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Button fill type="submit" intent="success" loading={updating}>
                  <span>Update</span>
                </Button>
              </FormGroup>
              {error && <FormError error={error} />}
            </form>
          )}
        </Formik>
      </Card>
    )
  }
}

export default ChangePassword
