import axios from 'axios'
import get from 'lodash/get'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { operations } from '../ducks/auth'

let store: any
let accessToken: string
let refreshToken: string

let listenerRegistered = false

let isRefreshing = false
const refreshSubscribers: any = []

const api = axios.create()

// $FlowFixMe
const baseUrl = `/api/v1`

function getAccessToken(state: any) {
  return state.auth.access
}

function getRefreshToken(state: any) {
  return state.auth.refresh
}

export function subscribeStore(_store: any) {
  if (!listenerRegistered) {
    store = _store
    store.subscribe(listener)
    listenerRegistered = true
  }
}

function listener() {
  const state = store.getState()
  accessToken = getAccessToken(state)
  refreshToken = getRefreshToken(state)
}

/**
 *
 */
export const fingerprint = async (): Promise<string> => {
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  return result.visitorId
}

/**
 *
 */
export const refreshAccessToken = async (): Promise<Record<string, any>> => {
  return fingerprint().then((result) => {
    return axios.post(
      `${baseUrl}/auth/refresh`,
      {
        deviceId: result,
      },
      { headers: { Authorization: refreshToken } }
    )
  })
}

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { config, response } = error
    const originalRequest = config

    if (!response) {
      return Promise.reject(error)
    } else if (get(response, 'config.url', '').indexOf('auth') >= 0) {
      return Promise.reject(error)
    } else if (
      response.status === 401 &&
      get(error, 'config.__isRetryRequest') !== true
    ) {
      originalRequest.baseURL = ''
      error.config.__isRetryRequest = true

      if (!isRefreshing) {
        isRefreshing = true
        return refreshAccessToken()
          .then((response: any) => {
            store.dispatch(operations.receiveRefresh(response.data))
            isRefreshing = false
            accessToken = response.data.access
            if (originalRequest && originalRequest.headers) {
              originalRequest.headers['Authorization'] = accessToken
            }
          })
          .then(() => retryQueued(accessToken))
          .then(() => axios(originalRequest))
          .catch((err) => {
            isRefreshing = false
            return Promise.reject(err)
          })
      } else {
        return wrapAddRequest(originalRequest)
      }

      /*
      return refreshAccessToken().then(response => {
        store.dispatch(operations.receiveRefresh(response.data))
        accessToken = response.data.access
        if (originalRequest && originalRequest.headers) {
          originalRequest.headers['Authorization'] = accessToken
        }
        return axios(originalRequest)
      })
      */
    } else {
      return Promise.reject(error)
    }
  }
)

api.interceptors.request.use((config) => {
  let token

  if (config.url && config.url.includes('auth/authenticate')) {
    // No token to add
    return config
  }

  if (config.url && config.url.includes('auth/refresh')) {
    token = refreshToken
  } else {
    token = accessToken
  }

  if (token && config && config.headers) {
    config.headers['Authorization'] = token
  }

  /*
  if (isRefreshing) {
    return wrapAddRequest(config)
  }
  */

  return config
})

/**
 *
 */

const wrapAddRequest = (originalRequest: any) =>
  new Promise((resolve, reject) => {
    refreshSubscribers.push((token: string) => {
      if (originalRequest && originalRequest.headers) {
        originalRequest.headers['Authorization'] = token
      }
      axios(originalRequest).then(resolve).catch(reject)
    })
  })

/**
 *
 */

const retryQueued = (token: string) => {
  let i = refreshSubscribers.length
  while (i--) {
    refreshSubscribers[i](token)
    refreshSubscribers.splice(i, 1)
  }
}

export default (
  url: string,
  method: string,
  body: any,
  config: any = { headers: {} },
  type?: string
) => {
  return api({
    ...config,
    url,
    method,
    data: method !== 'GET' ? body : null,
    headers: { ...config.headers },
  })
}
