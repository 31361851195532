import {
  invalidateUsers,
  createUser,
  editUser,
  editUserProfile,
  changeSelfPassword,
  changeUserPassword,
  deleteUser,
  fetchUsers,
} from './actions'

/**
 *
 */
const refreshUsers = () => (dispatch: any) => {
  dispatch(invalidateUsers())
  dispatch(fetchUsers())
}

export {
  invalidateUsers,
  createUser,
  editUser,
  editUserProfile,
  changeSelfPassword,
  changeUserPassword,
  deleteUser,
  fetchUsers,
  refreshUsers,
}
