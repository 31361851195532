import React from 'react'
import { Button, ButtonGroup, Card, HTMLTable } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

import format from 'date-fns/format'

import { Identicon } from '../identicon'

import { IUser as UserType } from '../types'

type Props = UserType

interface FooterProps {
  onEditUser: () => void
  onDeleteUser: () => void
  onChangeUserPassword: () => void
}

class Footer extends React.PureComponent<FooterProps> {
  public render(): React.ReactNode {
    return (
      <ButtonGroup>
        <Tooltip2 placement="top" content={'Change User Password'}>
          <Button
            intent="warning"
            style={{ margin: '2px' }}
            onClick={this.props.onChangeUserPassword}
            icon={'key'}
          />
        </Tooltip2>
        <Tooltip2 placement="top" content={'Edit user details'}>
          <Button
            intent="warning"
            style={{ margin: '2px' }}
            onClick={this.props.onEditUser}
            icon="edit"
          />
        </Tooltip2>
        <Tooltip2 placement="top" content={'Delete user'}>
          <Button
            intent="danger"
            style={{ margin: '2px' }}
            onClick={this.props.onDeleteUser}
            icon="trash"
          />
        </Tooltip2>
      </ButtonGroup>
    )
  }
}

export class User extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { props } = this
    return (
      <Card>
        <h5>User Details</h5>
        <div>
          <div>
            <div>
              <Identicon text={this.props.username} size={100} />
            </div>
            <div>
              <strong>{this.props.username}</strong>
              <HTMLTable>
                <tbody>
                  <tr>
                    <td>Full Name:</td>
                    <td>{this.props.fullName}</td>
                  </tr>
                  <tr>
                    <td>Registered Since:</td>
                    <td>{format(this.props.createdAt as any, 'YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{this.props.email}</td>
                  </tr>
                  <tr>
                    <td>Group:</td>
                    <td>{this.props.group}</td>
                  </tr>
                </tbody>
              </HTMLTable>
            </div>
          </div>
        </div>
        <div>
          <Footer
            onEditUser={(): void =>
              props.onEditUser && props.onEditUser({ ...props })
            }
            onDeleteUser={(): void =>
              props.onDeleteUser && props.onDeleteUser(props.username)
            }
            onChangeUserPassword={(): void =>
              props.onChangeUserPassword &&
              props.onChangeUserPassword(props.username)
            }
          />
        </div>
      </Card>
    )
  }
}

export default User
