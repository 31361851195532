import { State } from './reducers'

import has from 'lodash/has'

export function getErrors(state: State): string[] | null {
  if (has(state, 'error.message')) {
    return Object.keys(state.error.message).map(
      (m) => state.error.message[m].msg
    )
  } else {
    return null
  }
}
