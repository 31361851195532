import React from 'react'
import { Button, ButtonGroup } from '@blueprintjs/core'

import { Tooltip2 } from '@blueprintjs/popover2'

interface Props {
  onChangeUserPassword?: (a: any) => void
  onViewUser?: (a: any) => void
  onEditUser?: (a: any) => void
  onDeleteUser?: (a: any) => void
}

export const AccountToolbar = ({
  onChangeUserPassword,
  onViewUser,
  onEditUser,
  onDeleteUser,
}: Props): JSX.Element => (
  <ButtonGroup>
    <Tooltip2 placement="top" content={'Change Password'}>
      <Button
        intent="none"
        onClick={onChangeUserPassword}
        style={{ margin: '2px' }}
        icon="lock"
      />
    </Tooltip2>
    <Tooltip2 placement="top" content={'View'}>
      <Button
        intent="none"
        onClick={onViewUser}
        style={{ margin: '2px' }}
        icon="eye-open"
      />
    </Tooltip2>
    <Tooltip2 placement="top" content={'Edit'}>
      <Button
        intent="none"
        icon="edit"
        onClick={onEditUser}
        style={{ margin: '2px' }}
      />
    </Tooltip2>
    <Tooltip2 placement="top" content={'Delete'}>
      <Button
        intent="danger"
        icon="trash"
        onClick={onDeleteUser}
        style={{ margin: '2px' }}
      />
    </Tooltip2>
  </ButtonGroup>
)

export default AccountToolbar
