import * as React from 'react'
import { useState } from 'react'
import { Formik } from 'formik'

import {
  Button,
  Card,
  Radio,
  RadioGroup,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core'

import { useAppDispatch } from '../../state/hooks'
import { postProduct } from '../../state/ducks/products/operations'

import { CountrySelect } from './country-select'

export const ProductCreate = ({ ...props }) => {
  const dispatch = useAppDispatch()

  const [created, setCreated] = useState<boolean | undefined>()

  return (
    <Card>
      <h3>Add Product</h3>
      <Formik
        initialValues={{
          tagId: '',
          id: '',
          name: '',
          serial: '',
          url: '',
          type: 'validate',
          uri: '',
          description: '',
          destination: '',
        }}
        validate={(values: Record<string, string>) => {
          setCreated(undefined)
          const errors: any = {}

          const optional = ['destination', 'description']

          for (const val of Object.keys(values) as string[]) {
            if (!values[val] && optional.indexOf(val) < 0) {
              errors[val] = `${val} is required.`
            }
          }

          if (errors['uri'] && values.type === 'validate') {
            delete errors['uri']
          }

          if (errors['destination']) console.log(errors)
          return errors
        }}
        onSubmit={(
          { tagId, id, name, serial, url, type, uri, description, destination },
          actions
        ) => {
          actions.setSubmitting(true)
          let created: boolean
          dispatch(
            postProduct({
              tagId,
              metadata: {
                id,
                name,
                serial,
                url,
                description: description || undefined,
                destination: destination || undefined,
              },
              forwardAction: {
                type,
                uri: uri || undefined,
              },
            }) as any
          )
            .then(() => {
              created = true
            })
            .catch((err: any) => {
              console.error(err)
              created = false
            })
            .finally(() => {
              setTimeout(() => {
                setCreated(created)
                actions.setSubmitting(false)
              }, 250)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          console.log(errors)
          console.log(values)
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup
                label="Tag ID"
                labelFor="tag-id-input"
                labelInfo="(required)"
                helperText={
                  errors.tagId && touched.tagId && 'Tag ID is a required field'
                }
                intent={'tagId' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.tagId}
                  onChange={handleChange}
                  name="tagId"
                  id="tag-id-input"
                  placeholder="q-id.me/FB384FE.QB01"
                />
              </FormGroup>
              <FormGroup
                label="Product ID"
                labelFor="product-id-input"
                labelInfo=""
                helperText={
                  errors.id && touched.id && 'Product ID is a required field'
                }
                intent={'id' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.id}
                  onChange={handleChange}
                  name="id"
                  id="product-id-input"
                  placeholder="0123456789"
                />
              </FormGroup>
              <FormGroup
                label="Product Name"
                labelFor="product-name-input"
                labelInfo=""
                helperText={
                  errors.name &&
                  touched.name &&
                  'Product Name is a required field'
                }
                intent={'name' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  id="product-name-input"
                  placeholder="Demo Product One"
                />
              </FormGroup>
              <FormGroup
                label="Product Serial"
                labelFor="product-serial-input"
                labelInfo=""
                helperText={
                  errors.serial &&
                  touched.serial &&
                  'Product Name is a required field'
                }
                intent={'serial' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.serial}
                  onChange={handleChange}
                  name="serial"
                  id="product-serial-input"
                  placeholder="0123456789"
                />
              </FormGroup>
              <FormGroup
                label="Product URL"
                labelFor="product-url-input"
                labelInfo=""
                helperText={
                  errors.url && touched.url && 'Product URL is a required field'
                }
                intent={'url' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.url}
                  onChange={handleChange}
                  id="product-url-input"
                  name="url"
                  placeholder="q-id.me/products/0123456789"
                />
              </FormGroup>
              <FormGroup
                label="Product Description"
                labelFor="product-description-input"
                labelInfo=""
                helperText={
                  errors.url &&
                  touched.url &&
                  'Product Description is a required field'
                }
                intent={'description' in errors ? 'danger' : 'none'}
              >
                <InputGroup
                  value={values.description}
                  onChange={handleChange}
                  id="product-description-input"
                  name="description"
                  placeholder="A more detailed description of your product."
                />
              </FormGroup>

              <FormGroup
                label="Product Destination"
                labelFor="product-destination-input"
                labelInfo=""
                helperText={
                  errors.url &&
                  touched.url &&
                  'Product Description is a required field'
                }
                intent={'destination' in errors ? 'danger' : 'none'}
              >
                <CountrySelect
                  onItemSelected={(item?: string) => {
                    setFieldValue('destination', item)
                  }}
                />
              </FormGroup>

              <RadioGroup
                label="Action Type"
                name="type"
                inline
                onChange={handleChange}
                selectedValue={values.type}
              >
                <Radio label="Validate" value="validate" />
                <Radio label="Redirect" value="redirect" />
                <Radio label="Resource" value="resource" />
              </RadioGroup>
              {['resource', 'redirect'].indexOf(values.type) >= 0 && (
                <FormGroup
                  label="Action Resource"
                  labelFor="product-action-uri-input"
                  labelInfo=""
                  helperText={
                    errors.uri &&
                    touched.uri &&
                    `Action URL is a required field, when type is 'resource' or 'redirect'`
                  }
                  intent={'uri' in errors ? 'danger' : 'none'}
                >
                  <InputGroup
                    value={values.uri}
                    name="uri"
                    onChange={handleChange}
                    id="product-action-uri-input"
                    placeholder=""
                  />
                </FormGroup>
              )}
              <Button
                disabled={Object.keys(errors).length > 0}
                fill
                loading={isSubmitting}
                intent="primary"
                type="submit"
              >
                Create
              </Button>
            </form>
          )
        }}
      </Formik>
      {created === true && (
        <div style={{ color: 'green', marginTop: '12px', textAlign: 'center' }}>
          Product created
        </div>
      )}
      {created === false && (
        <div style={{ color: 'red', marginTop: '12px', textAlign: 'center' }}>
          Failed to create product, check fields.
        </div>
      )}
    </Card>
  )
}
