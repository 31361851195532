import React from 'react'

interface Props {
  error: string
}

const FormError = ({ error }: Props) => (
  <span
    style={{
      color: 'red',
      textAlign: 'center',
      whiteSpace: 'pre-line',
    }}
  >
    {error}
  </span>
)

export default FormError
