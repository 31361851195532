import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import store from './state/store'
import { Provider } from 'react-redux'

import { BrowserRouter } from 'react-router-dom'

import App from './app/app'

const rootEl = document.getElementById('root')
const root = createRoot(rootEl!!)

root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </StrictMode>
)
