import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { get } from 'lodash'

import {
  Button,
  Card,
  InputGroup,
  Spinner,
  Intent,
  Tag,
} from '@blueprintjs/core'

import { useAppSelector, useAppDispatch } from '../../state/hooks'

import { TagErrorList } from './tag-error-list'
import {
  fetchPagedTagErrors,
  fetchTagErrorCount,
  deleteTagErrors,
} from '../../state/ducks/tag-errors/operations'

interface Props {}

const QIDS_PAGE = 10

export const TagErrorPage = ({ ...props }: Props) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

  const dispatch = useAppDispatch()
  const params = useParams() as any

  const tagErrors = useAppSelector((state) => state.tagErrors)
  const batch = useAppSelector((state) => state.batches.byId[params.batchId])

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState<boolean>(false)

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (isAuthenticated) {
      if (!search) {
        dispatch(fetchTagErrorCount(params.batchId) as any)
        dispatch(
          fetchPagedTagErrors(
            (page - 1) * QIDS_PAGE,
            QIDS_PAGE,
            params.batchId
          ) as any
        )
      }
    }
  }, [dispatch, isAuthenticated, page, params.batchId, search])

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onSearch = () => {
    dispatch(fetchPagedTagErrors(0, 1000, params.batchId, query) as any)
    setSearch(true)
  }

  if (!batch || tagErrors.fetching) {
    return <Spinner />
  }

  const count = get(tagErrors, ['count'], 0)

  return (
    <Card>
      {count > 0 && (
        <React.Fragment>
          <h4 style={{ textAlign: 'center' }}>
            {count} tags failed to process.
          </h4>

          <div>
            {search ? (
              <Tag
                large
                interactive
                onRemove={() => {
                  setSearch(false)
                }}
              >
                {query}
              </Tag>
            ) : (
              <InputGroup
                leftIcon="search"
                placeholder="Search"
                onChange={(ev) => {
                  console.log('onChange:', ev.currentTarget.value)
                  setQuery(ev.currentTarget.value)
                }}
                rightElement={
                  <Button
                    onClick={onSearch}
                    value={query}
                    minimal
                    intent={Intent.PRIMARY}
                    icon={'arrow-right'}
                  />
                }
              />
            )}
          </div>
        </React.Fragment>
      )}

      <TagErrorList
        page={page}
        totalPages={search ? 0 : Math.ceil(count / QIDS_PAGE)}
        onPageChange={onPageChange}
        patches={tagErrors}
        ks={batch.idGenParams.ks}
      />

      {count > 0 && (
        <div
          style={{
            margin: '16px',
          }}
        >
          <Button
            intent="danger"
            fill
            large
            icon="trash"
            onClick={() => {
              dispatch(deleteTagErrors(params.batchId) as any)
            }}
          >
            Delete All
          </Button>
        </div>
      )}
    </Card>
  )
}
