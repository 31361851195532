import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_LOGIN: null,
    FETCH_LOGIN_FULFILLED: null,
    FETCH_LOGIN_REJECTED: null,
    LOGOUT_REQUEST: null,
    LOGOUT_FULFILLED: null,
    FETCH_REFRESH: null,
    FETCH_REFRESH_FULFILLED: null,
    FETCH_REFRESH_REJECTED: null,
    LOAD_TOKENS: null,
  },
  'auth'
)
