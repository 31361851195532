import { handleActions } from 'redux-actions'

import types from './types'

export interface State {
  params: {
    R: number
    N: number
    ks: number
    error?: string
  }
}

const initialState: State = {
  params: {
    R: 0,
    N: 0,
    ks: 0,
  },
}

const actionHandler = {
  [types.FETCH_PARAMS]: (state: State) => ({ ...state, fetching: true }),
  [types.FETCH_PARAMS_REJECTED]: (state: State, action: any) => ({
    ...state,
    fetching: false,
    error: action.payload.message,
  }),
  [types.FETCH_PARAMS_FULFILLED]: (state: State, action: any) => {
    const data = action.payload.data

    return {
      ...state,
      params: {
        ...data,
        error: undefined,
      },
    }
  },
}

/* Reducer */

export default handleActions(actionHandler, {
  ...initialState,
})
