import * as react from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Button, Card } from '@blueprintjs/core'

import { ParamField } from './param-field'

const IdPoolSchema = Yup.object().shape({
  tolerance: Yup.number().required('tolerance is required'),
  interAvg: Yup.number().required('interAvg is required'),
  interStd: Yup.number().required('interStd is required'),
  numberOfBits: Yup.number().required('numberOfBits is required'),
  degreesOfFreedom: Yup.number().required('degreesOfFreedom is required'),
  limit: Yup.number().required('limit is required'),
})

export const IdentityPool = ({ onSubmit, identityPoolCheck }: any) => (
  <Card>
    <h5>Identity Pool Check</h5>
    <Formik
      enableReinitialize
      initialValues={{
        tolerance: identityPoolCheck.thresholds.uniformity.tolerance,
        interAvg: identityPoolCheck.thresholds.interAvg,
        interStd: identityPoolCheck.thresholds.interStd,
        numberOfBits: identityPoolCheck.thresholds.numberOfBits,
        degreesOfFreedom: identityPoolCheck.thresholds.degreesOfFreedom,
        limit: identityPoolCheck.settings.limit,
      }}
      validationSchema={IdPoolSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <h6>Thresholds</h6>
          <ParamField
            errors={errors}
            touched={touched}
            label="Uniformity Tolerance"
            name="tolerance"
          />
          <ParamField
            errors={errors}
            touched={touched}
            label="Inter Avg"
            name="interAvg"
          />
          <ParamField
            errors={errors}
            touched={touched}
            label="Inter Std"
            name="interStd"
          />
          <ParamField
            errors={errors}
            touched={touched}
            label="Number of Bits"
            name="numberOfBits"
          />
          <ParamField
            errors={errors}
            touched={touched}
            label="Degrees of Freedom"
            name="degreesOfFreedom"
          />

          <h6>Settings</h6>
          <ParamField
            errors={errors}
            touched={touched}
            name="limit"
            label="Identity Limit"
          />
          <Button icon="floppy-disk" type="submit">
            Save
          </Button>
        </Form>
      )}
    </Formik>
  </Card>
)
