import types from './types'

/**
 *
 */
export const fetchVerifyKey = () => ({
  type: types.FETCH_VERIFY_KEY,
  payload: null,
  meta: {
    path: `/keys`,
    method: 'GET',
    async: true,
    config: {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    },
  },
})
