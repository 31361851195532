import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    FETCH_TAG_ERRORS: null,
    FETCH_TAG_ERRORS_FULFILLED: null,
    FETCH_TAG_ERRORS_COMPLETED: null,
    FETCH_TAG_ERRORS_REJECTED: null,

    DELETE_TAG_ERRORS: null,
    DELETE_TAG_ERRORS_FULFILLED: null,
    DELETE_TAG_ERRORS_REJECTED: null,

    FETCH_TAG_ERROR_COUNT: null,
    FETCH_TAG_ERROR_COUNT_FULFILLED: null,
    FETCH_TAG_ERROR_COUNT_COMPLETED: null,
    FETCH_TAG_ERROR_COUNT_REJECTED: null,
  },
  'tagErrors'
)
