import * as React from 'react'

import { ProductCreate } from './product-create'
import { ProductUpload } from './product-upload'
import { ProductFind } from './product-find'

export const ProductPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ margin: '16px', minWidth: '345px' }}>
        <ProductCreate />
      </div>
      <div style={{ margin: '16px', minWidth: '345px' }}>
        <ProductUpload />
      </div>
      <div style={{ margin: '16px', minWidth: '345px' }}>
        <ProductFind />
      </div>
    </div>
  )
}
