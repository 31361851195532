import { fetchImages, fetchImageData, deleteImages } from './actions'

const fetchPagedImages =
  (offset: number, limit: number, batchId?: string, payload?: string) =>
  (dispatch: (f: any) => Promise<any>) => {
    let qs = `?offset=${offset}&limit=${limit}${
      batchId ? `&batchId=${batchId}` : ''
    }`

    if (payload) {
      const encoded = btoa(payload)
      qs = `${qs}&payload=${encoded}`
    }

    return dispatch(fetchImages(qs))
  }

export { fetchPagedImages, fetchImages, fetchImageData, deleteImages }
