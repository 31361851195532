import { handleActions } from 'redux-actions'

import types from './types'

export interface ImageSettings {
  save: boolean
}

export interface IdentityPoolSettings {
  thresholds: {
    uniformity: {
      tolerance: number
    }
    interAvg: number
    interStd: number
    numberOfBits: number
    degreesOfFreedom: number
  }
  settings: {
    limit: number
  }
}

interface ApiState {
  error?: string
  fetching: boolean
}

export interface State {
  images: ImageSettings & ApiState
  identityPoolCheck: IdentityPoolSettings & ApiState
}

const initialState: State = {
  images: {
    save: false,
    fetching: false,
  },
  identityPoolCheck: {
    thresholds: {
      uniformity: {
        tolerance: 0,
      },
      interAvg: 0,
      interStd: 0,
      numberOfBits: 0,
      degreesOfFreedom: 0,
    },
    settings: {
      limit: 0,
    },
    fetching: false,
  },
}

const actionHandler = {
  [types.FETCH_IMAGE_SETTINGS]: (state: State) => ({
    ...state,
    images: {
      ...state.images,
      fetching: true,
      error: undefined,
    },
  }),
  [types.FETCH_IMAGE_SETTINGS_REJECTED]: (state: State, action: any) => ({
    ...state,
    images: {
      ...state.images,
      fetching: false,
      error: action.payload.message,
    },
  }),
  [types.FETCH_IMAGE_SETTINGS_FULFILLED]: (state: State, action: any) => {
    const data = action.payload.data

    return {
      ...state,
      images: {
        ...data,
        error: undefined,
      },
    }
  },

  [types.FETCH_ID_POOL_SETTINGS]: (state: State) => ({
    ...state,
    identityPoolCheck: {
      ...state.identityPoolCheck,
      fetching: true,
      error: undefined,
    },
  }),
  [types.FETCH_ID_POOL_SETTINGS_REJECTED]: (state: State, action: any) => ({
    ...state,
    identityPoolCheck: {
      ...state.identityPoolCheck,
      fetching: false,
      error: action.payload.message,
    },
  }),
  [types.FETCH_ID_POOL_SETTINGS_FULFILLED]: (state: State, action: any) => {
    const data = action.payload.data

    return {
      ...state,
      identityPoolCheck: {
        ...data,
        error: undefined,
      },
    }
  },
}

/* Reducer */

export default handleActions(actionHandler, {
  ...initialState,
})
