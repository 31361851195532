import * as React from 'react'

import {
  NonIdealState,
  Button,
  Icon,
  Spinner,
  IconSize,
} from '@blueprintjs/core'

interface Props {
  error: string
}

export const Authentication = ({ ...props }: Props) => {
  const { error } = props

  if (error) {
    return (
      <NonIdealState
        icon={<Icon icon="warning-sign" intent="danger" size={60} />}
        title="Cloud Authentication Failed"
        description={error}
        action={
          <Button
            onClick={() => {
              window.location.reload()
            }}
          >
            Retry
          </Button>
        }
      />
    )

    /*
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon icon="warning-sign" intent="danger" />
        <p
          key="errors"
          className="text-center"
          style={{ color: 'red', paddingTop: '8px' }}
        >
          {error}
        </p>
      </div>
    )
    */
  }

  return (
    <div>
      <Spinner />
      <p style={{ marginTop: '16px' }}>Authenticating with MVS Cloud</p>
    </div>
  )
}

export default Authentication
