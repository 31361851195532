import React from 'react'

import {
  Alignment,
  Button,
  Classes,
  Navbar,
  Menu,
  MenuItem,
} from '@blueprintjs/core'
import {
  Classes as PopClasses,
  Popover2 as Popover,
} from '@blueprintjs/popover2'
import { useNavigate, NavLink } from 'react-router-dom'

interface Props {
  isAuthenticated: boolean
  logout: () => void
  isAdmin: boolean
}

export function Nav({ isAuthenticated, logout, isAdmin }: Props) {
  const navigate = useNavigate()

  if (isAuthenticated) {
    return (
      <Navbar className={Classes.DARK}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>Quantum Base</Navbar.Heading>
          <NavLink to="/">
            {({ isActive }) => (
              <Button
                active={isActive}
                className={Classes.MINIMAL}
                text={'Home'}
              />
            )}
          </NavLink>
          <NavLink to="/products">
            {({ isActive }) => (
              <Button
                active={isActive}
                className={Classes.MINIMAL}
                text={'Products'}
              />
            )}
          </NavLink>
          {isAdmin && (
            <NavLink to="/settings">
              {({ isActive }) => (
                <Button
                  active={isActive}
                  className={Classes.MINIMAL}
                  text={'Settings'}
                />
              )}
            </NavLink>
          )}
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Popover
            popoverClassName={PopClasses.CONTEXT_MENU2}
            content={
              <Menu>
                <MenuItem text="No notifications" />
              </Menu>
            }
            placement="bottom-end"
          >
            <Button className={Classes.MINIMAL} icon="notifications" />
          </Popover>

          <Popover
            popoverClassName={PopClasses.CONTEXT_MENU2}
            content={
              <Menu>
                {isAdmin && (
                  <MenuItem
                    icon="user"
                    onClick={() => navigate('/users')}
                    text="Users"
                  />
                )}
                <MenuItem
                  icon="log-out"
                  onClick={() => logout()}
                  text="Sign Out"
                />
              </Menu>
            }
            placement="bottom-end"
          >
            <Button className={Classes.MINIMAL} icon="user" />;
          </Popover>
        </Navbar.Group>
      </Navbar>
    )
  } else {
    return (
      <Navbar className={Classes.DARK}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>Quantum Base</Navbar.Heading>
        </Navbar.Group>
      </Navbar>
    )
  }
}

export default Nav
