import types from './types'

import { QidParams } from '@qido/shared'

/**
 *
 */
export const fetchParams = () => ({
  type: types.FETCH_PARAMS,
  payload: null,
  meta: {
    path: `/qids/params`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const postParams = ({ R, N, ks }: QidParams) => ({
  type: types.POST_PARAMS,
  payload: {
    R,
    N,
    ks,
  },
  meta: {
    path: `/qids/params`,
    method: 'POST',
    async: true,
    body: {
      R,
      N,
      ks,
    },
  },
})
