import React, { Fragment, PureComponent } from 'react'

import { FormGroup, InputGroup } from '@blueprintjs/core'

interface Props {
  isInvalid: boolean
  password: string
  onPasswordChange: (a: any) => void
  onConfirmChange: (a: any) => void
  passwordsMatch: boolean
}

const passwordHelp =
  'Passwords should be at least 8 characters. Use a variety of upper, lower case, numbers and special characters to increase password strength.'

const confirmPasswordHelp = "Entered passwords don't match."

export default class ConfirmPasswordInput extends PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <Fragment>
        <FormGroup
          intent={this.props.isInvalid ? 'danger' : 'none'}
          label="Password"
          helperText={this.props.isInvalid ? passwordHelp : ''}
        >
          <InputGroup
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="password"
            onChange={this.props.onPasswordChange}
            style={{ width: '100%' }}
            leftIcon="lock"
          />
        </FormGroup>
        <FormGroup
          intent={this.props.passwordsMatch ? 'none' : 'danger'}
          label="Confirm Password"
          helperText={this.props.passwordsMatch ? '' : confirmPasswordHelp}
        >
          <InputGroup
            style={{ width: '100%' }}
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
            placeholder="Confirm Password"
            onChange={this.props.onConfirmChange}
            leftIcon="lock"
          />
        </FormGroup>
      </Fragment>
    )
  }
}
