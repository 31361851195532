import * as React from 'react'
import { Field } from 'formik'

import { FormGroup, InputGroup } from '@blueprintjs/core'

const getIntent = (error?: string[], touched?: boolean) => {
  if (error && touched) {
    return 'danger'
  } else if (touched) {
    return 'success'
  } else {
    return 'none'
  }
}

export const ParamField = ({ name, label, errors, touched }: any) => {
  return (
    <Field name={name}>
      {({ field }: any) => (
        <FormGroup
          label={label}
          inline={false}
          intent={getIntent(errors[name], touched[name])}
          helperText={touched[name] && errors[name]}
        >
          <InputGroup
            type="number"
            intent={getIntent(errors[name], touched[name])}
            {...field}
            placeholder={label}
          />
        </FormGroup>
      )}
    </Field>
  )
}
