import * as React from 'react'

import ErrorTemplate from './errors/error-template'

/**
 *
 */
export default function NotFoundPage() {
  return (
    <ErrorTemplate
      title={`404 Not Found`}
      details={`Sorry, the requested page was not found!`}
    />
  )
}
