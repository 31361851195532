import types from './types'

/**
 *
 */
function removeEmptyKeys(obj: any) {
  for (const prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
      delete obj[prop]
    }
  }
  return obj
}

/**
 *
 */
export const invalidateUsers = () => ({
  type: types.INVALIDATE_USERS,
})

/**
 *
 */
export const createUser = (user: any) => ({
  type: types.CREATE_USER,
  meta: {
    path: `/users`,
    method: 'POST',
    async: true,
    body: user,
  },
})

/**
 *
 */
export const editUser = (username: string, user: any) => ({
  type: types.EDIT_USER,
  meta: {
    path: `/users/${username}`,
    method: 'PATCH',
    async: true,
    body: removeEmptyKeys(user),
  },
})

/**
 *
 */
export const editUserProfile = (user: any) => ({
  type: types.EDIT_USER,
  meta: {
    path: `/profile`,
    method: 'PATCH',
    async: true,
    body: removeEmptyKeys(user),
  },
})

/**
 *
 */
export const changeSelfPassword = (password: string, newPassword: string) => ({
  type: types.CHANGE_PASSWORD,
  meta: {
    path: `/profile/password`,
    method: 'PUT',
    async: true,
    body: { password, newPassword },
  },
})

/**
 *
 */
export const changeUserPassword = (username: string, newPassword: string) => ({
  type: types.CHANGE_PASSWORD,
  meta: {
    path: `/users/${username}/password`,
    method: 'PUT',
    async: true,
    body: { newPassword },
  },
})

/**
 *
 */
export const deleteUser = (username: string) => ({
  type: types.DELETE_USER,
  meta: {
    path: `/users/${username}`,
    method: 'DELETE',
    async: true,
  },
})

/**
 *
 */
export const fetchUsers = () => ({
  type: types.FETCH_USERS,
  meta: {
    path: `/users`,
    method: 'GET',
    async: true,
  },
})
