import KeyMirror from '../../../util/key-mirror'

export default KeyMirror(
  {
    CANCEL_USER_UPDATE: null,
    CREATE_USER: null,
    CREATE_USER_FULFILLED: null,
    CREATE_USER_REJECTED: null,
    CHANGE_PASSWORD: null,
    CHANGE_PASSWORD_FULFILLED: null,
    CHANGE_PASSWORD_REJECTED: null,
    EDIT_USER: null,
    EDIT_USER_FULFILLED: null,
    EDIT_USER_REJECTED: null,
    READ_USER: null,
    UPDATE_USER: null,
    DELETE_USER: null,
    DELETE_USER_FULFILLED: null,
    DELETE_USER_REJECTED: null,
    FETCH_USERS: null,
    FETCH_USERS_FULFILLED: null,
    FETCH_USERS_REJECTED: null,
    INVALIDATE_USERS: null,
  },
  'users'
)
