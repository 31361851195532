import * as React from 'react'

interface Props {
  title: string
  details: string
}

function ErrorPage({ ...props }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="error-template"
        style={{ padding: '40px 15px', textAlign: 'center', flex: 1 }}
      >
        <h2>{props.title}</h2>
        <div className="error-details" style={{ margin: '30px' }}>
          {props.details}
        </div>
        <div className="error-actions" style={{ margin: '30px' }}>
          <a
            href="/"
            className="btn btn-primary btn-lg"
            style={{ marginRight: '10px' }}
          >
            Take Me Home
          </a>
          <a
            href="https://www.quantumbase.com"
            className="btn btn-primary btn-lg"
            style={{ marginRight: '10px' }}
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  )
}

/** @component */
export default ErrorPage
