import isNil from 'lodash/isNil'

import { State } from './reducers'

const adminGroups = ['root', 'admin']

export function isAdmin(state: State): boolean {
  if (!isNil(state.groups) && Array.isArray(state.groups)) {
    return (
      adminGroups.filter((n) => (state.groups || []).includes(n)).length > 0
    )
  } else {
    return false
  }
}
