import types from './types'

/**
 *
 */
export const fetchPatches = (query?: string) => ({
  type: types.FETCH_PATCHES,
  payload: null,
  meta: {
    path: `/identities${query || ''}`,
    method: 'GET',
    async: true,
  },
})

/**
 *
 */
export const fetchPatchesForBatch = (id: string) =>
  fetchPatches(`?batchId=${id}`)

/**
 *
 */
export const fetchCertificate = (payload: string) => ({
  type: types.FETCH_CERTIFICATE,
  payload: null,
  meta: {
    path: `/identities/${payload}/certificate`,
    method: 'GET',
    async: true,
    config: {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    },
  },
})
