export function download(dataUri: string, fileName: string) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = dataUri
  link.setAttribute('download', fileName)
  const body = document.body
  if (body) {
    body.appendChild(link)
    link.click()
    body.removeChild(link)
  }
}
