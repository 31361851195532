import * as React from 'react'

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { Spinner } from '@blueprintjs/core'

import { useAppSelector } from '../../state/hooks'

import { BatchList } from '../batches/batch-list'

import { ClientBatchInfo } from './client-batch-info'

export const ClientBatchPage = ({ ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const batches = useAppSelector((state) => state.batches)

  const selectBatch = (batchId: string) => {
    navigate(`/batch/${batchId}/info`)
  }

  const getBatchId = (): string | undefined => {
    const params = location.pathname.split('/')

    if (params.length > 2 && params[1] === 'batch') {
      return params[2]
    }
    return
  }

  const content = batches.fetching ? (
    <Spinner />
  ) : (
    <div style={{ margin: '6px' }}>
      <BatchList
        selectedBatch={getBatchId()}
        batches={batches}
        onSelectBatch={selectBatch}
      />
    </div>
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
      }}
    >
      {content}
      <Routes>
        <Route
          path="/batch/:batchId"
          element={
            <div style={{ margin: '16px' }}>
              <Route path="/batch/:batchId/info">
                <ClientBatchInfo
                  {...props}
                  batch={batches.byId[props.match.params.batchId]}
                />
              </Route>
            </div>
          }
        ></Route>

        <Route
          path="/"
          element={
            <div style={{ margin: '16px' }}>
              <h3>Select a batch</h3>
            </div>
          }
        ></Route>
      </Routes>
    </div>
  )
}
