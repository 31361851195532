import { fingerprint } from '../../utils/fetch'

import {
  requestRefresh,
  receiveRefresh,
  refreshError,
  requestLogin,
  receiveLogin,
  loginError,
  requestLogout,
  receiveLogout,
} from './actions'

/**
 *
 */
const logout =
  (): any =>
  (dispatch: any): any => {
    dispatch(requestLogout())
    dispatch(receiveLogout())
  }

/**
 *
 */
const login =
  (creds: any): any =>
  (dispatch: any): any => {
    return fingerprint().then((result: string) =>
      dispatch(requestLogin(creds.username, creds.password, result))
    )
  }

/**
 *
 */
const refreshToken = () => (dispatch: any) => {
  fingerprint().then((result: string) => dispatch(requestRefresh(result)))
}

export {
  requestRefresh,
  receiveRefresh,
  refreshError,
  requestLogin,
  receiveLogin,
  loginError,
  requestLogout,
  receiveLogout,
  logout,
  login,
  refreshToken,
}
